import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import * as DatabaseLib from '@/assets/js/DatabaseLib.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('token') || "",
    user: sessionStorage.getItem('user') || "",
    id: sessionStorage.getItem('id') || "",
    email: sessionStorage.getItem('email') || "",
    role: sessionStorage.getItem('role') || "",
    groups: sessionStorage.getItem('groups') || "",
    avatar: sessionStorage.getItem('avatar') || "",
    status: ""
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token, user){
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
  },
  actions: {
    login({commit}, user){
       return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post(DatabaseLib.getEndpoint('strapiDatabase') + 'api/auth/local', {
					identifier: user.email,
					password: user.password,
				})
        .then(response => {
          const token = response.data.jwt;
          const user = response.data.user;
       
          axios.get(DatabaseLib.getEndpoint('strapiDatabase') + 'api/users?filters[username][$eq][0]='+user.username+'&populate[1]=role&populate[2]=profilePic&populate[3]=groups', 
            { headers: { Authorization: 'Bearer '+token, 'Content-Type': 'application/json' }})
            .then(res => {
              sessionStorage.setItem('token', token);
              sessionStorage.setItem('user', user.username);
              sessionStorage.setItem('id', user.id);
              sessionStorage.setItem('email', user.email);
              sessionStorage.setItem('role', res.data[0].role.name);
              sessionStorage.setItem('avatar', DatabaseLib.getEndpoint('strapiDatabase').slice(0, -1) + res.data[0].profilePic[0].url);
            
              let groups = [];
              for(let i = 0; i < res.data[0].groups.length; i = i + 1) {
                groups.push(res.data[0].groups[i].name);
              }
              sessionStorage.setItem('groups', groups);

              var data = new FormData();
              data.append('token', token);
              data.append('user', user.username);
              data.append('id', user.id);
              data.append('action', 'store'); // action is store or variable getters

              // Store user data at server side PHP file
              
              /*
              try {
              axios.post('http://192.168.77.93:80/QCDB/PHP-Files/store_data.php', data).then(response => {
                console.log(response);
              })
              } catch (error) {
                console.log(error);			
              }
              */
              
              let loginData = {
                lastLogin: new Date()
              }
              DatabaseLib.putRequest('strapiDatabase','users',token,user.id,loginData);
              axios.defaults.headers.common['Authorization'] = token
              commit('auth_success', token, user)
              resolve(response)
            })
          })            
          .catch(err => {
            commit('auth_error')
            sessionStorage.removeItem('token')
            reject(err)
          })
      })
    },
    logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('email')
        sessionStorage.removeItem('role')
        sessionStorage.removeItem('groups')
        sessionStorage.removeItem('avatar')
        //sessionStorage.removeItem("loginTime");
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  },	
  methods:{

  },
  modules: {
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    getUser: state => state.user,
    getToken: state => state.token,
    getId: state => state.id,
    getEmail: state => state.email,
    getRole: state => state.role,
    getGroups: state => state.groups,
    getAvatar: state => state.avatar,
  }
});
