<template>
<div class='page-start'>
	<mdb-container class="pb-3" fluid>

    <!-- User Feedback and Error Code Displays -->

    <mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p1" @closeAlert="p1=false" dismiss>
        Success! Submitted Support Ticket!
    </mdb-alert>
    <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
        There was an error submitting Support Ticket. Please try again! Error Code P2
    </mdb-alert>
    <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p3" @closeAlert="p3=false" dismiss>
        There was an error populating ticket data. Please try again! Error Code P3
    </mdb-alert>
    <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p4" @closeAlert="p4=false" dismiss>
        There was an error populating "Assigned To" dropdown. Please try again! Error Code P4
    </mdb-alert>
    <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p5" @closeAlert="p5=false" dismiss>
        There was an error fetching ticket data. Please try again! Error Code P5
    </mdb-alert>

    <!-- END User Feedback and Error Code Displays -->


    <mdb-modal :show="priorityModal" @close="priorityModal = false" centered>
      <mdb-modal-header>
        <mdb-modal-title>About Priority levels</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="text-left">
        <ol>
          <li><u>High:</u> Needs to be done ASAP</li>
          <li><u>Above Average:</u> Needs to be done at the next available time slot</li>
          <li><u>Medium:</u> Needs to be done when there is some free time</li>
          <li><u>Below Average:</u> Needs to be done when it is not busy</li>
          <li><u>Low:</u> Does not need to be done soon</li>
        </ol>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" @click.native="priorityModal = false">Close</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>


    
    <mdb-card class="mb-3 d-flex">
    <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Unassigned Support Tickets</h3>
    <mdb-card-body>
        <QDataTable id='unassignedTable' :rowData="this.ticketData.unassigned.rowData" :filterWords="this.ticketData.unassigned.filterWords" :headers="this.ticketData.unassigned.columns" 
        :btnName="'Manage'" :hasBtn="true" @use-method='manageUnassignedTicket'>
        </QDataTable>
    </mdb-card-body>
    </mdb-card>
  

    <!-- Create Ticket Table -->
    <mdb-card class="mb-3 d-flex" id="ticketForm">
    <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4"> Manage Support Ticket</h3>
    <mdb-card-body>
      <form class="was-validated" >
        <mdb-row>
          <mdb-col>
            <mdb-input label="Description" v-model="description" required invalid-feedback="Please Input Ticket Description" valid-feedback="Looks Good!" disabled maxlength="500" />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col> 
            <mdb-select v-model="subjectOptions" label="Subject" :placeholder="subject" @getValue="setSubject" required :disabled="manageTicketID == ''" maxlength="20"/>
          </mdb-col>
          <mdb-col>
            <p class="h5 mb-n4">Priority <mdb-icon icon="question-circle" size="sm" class="indigo-text" style="cursor: pointer;" @click.native="priorityModal = true" /></p>
            <mdb-input type="number" v-model="priority" :max='5' :min='1' required invalid-feedback="Please Input Priority" valid-feedback="Looks Good!" :disabled="manageTicketID == ''" />
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input v-model="dateOpened" label='Date Opened' id="dateOpened" title='Date Opened' disabled />
          </mdb-col>
          <mdb-col>
            <mdb-date-picker-2 v-model="dateClosed" label='Date Closed' id="dateClosed" title='Date Closed' disabledFuture :disabled="manageTicketID == ''"/>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input label="Name of Submitter" v-model="nameSubmit" required disabled/>
          </mdb-col>
          <mdb-col>
            <mdb-input label="Email of Submitter" v-model="emailSubmit" required disabled/>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-select v-model="assignToOptions" label="Assigned To"  :placeholder="assignTo" @getValue="setAssignTo" :disabled="manageTicketID == ''" />
          </mdb-col>
          <mdb-col>
            <p class="h5">Resolved: </p>
            <mdb-form-inline class="justify-content-center" novalidate >
              <mdb-input type="radio" id="resolvedFalse" name="resolved" radioValue="false" v-model="resolved" label="No" checked required :disabled="manageTicketID == ''"/>
              <mdb-input type="radio" id="resolvedTrue" name="resolved" radioValue="true" v-model="resolved" label="Yes"  required :disabled="manageTicketID == ''"/>
            </mdb-form-inline>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-input label="Solution *" v-model="solution" :disabled="resolved == 'true' ? false : true" valid-feedback="Optional Field. Will only be allowed to fill out if ticket is resolved." required/>
            <p>* This is to document any complex solutions so they can be easily implemented later</p>
          </mdb-col>
        </mdb-row>
        <!--<mdb-btn id="submitAddTicket" class='QuadrusButton' size="lg" v-on:click="submitTicket" :disabled="manageTicketID == ''">Submit</mdb-btn>-->
        <mdb-btn size="block" class="mt-4" color="primary" type="submit" v-on:click="submitTicket" v-if="!submitProcessing">Submit</mdb-btn>
        <mdb-btn size="block" class="mt-4" color="primary" v-if="submitProcessing">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Processing...
        </mdb-btn>
        <br>
        <mdb-btn id="clearTicket" color="danger" size="lg" v-on:click="clearTicketForm" :disabled="manageTicketID == ''">Clear</mdb-btn>
      </form>
    </mdb-card-body>
    </mdb-card>

    <mdb-card class="mb-3 d-flex">
      <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Quadrus Corporation Support Tickets</h3>
      <mdb-card-body>
        <mdb-input class="mb-0 mt-2" type="checkbox" id="showResolved" name="showResolved" v-model="showResolved" label="Show Resolved"/>
        <QDataTable id='ticketTable' :rowData="this.ticketData.assigned.rowData" :filterWords="this.ticketData.assigned.filterWords" :headers="this.ticketData.assigned.columns" 
        :btnName="'Manage'" :hasBtn="true" @use-method='manageTicket'>
        </QDataTable>
      </mdb-card-body>
    </mdb-card>
  </mdb-container>
</div>
</template>
<style>
  div i {
    padding-right: 1.5rem;
  }
</style>
<script>
import { mdbInput, mdbCard, mdbCardBody, mdbContainer, mdbBtn, mdbSelect, mdbRow, mdbCol, mdbDatePicker2, mdbFormInline, mdbIcon, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbAlert } from 'mdbvue';
import QDataTable from '@/components/QDataTable.vue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';

export default {
	name: 'IT-Tickets',
	components: {
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbContainer,
    mdbBtn,
    mdbSelect,
    QDataTable,
    mdbRow,
    mdbCol,
    mdbDatePicker2,
    mdbFormInline,
    mdbIcon,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbAlert
    },
    
    data() {
      return {

        p1: false,
        p2: false,
        p3: false,
        p4: false,
        p5: false,

        description: '',
        subject: "",
        priority: 3,
        dateOpened: '',
        dateClosed: '',
        nameSubmit: '',
        emailSubmit: '',
        assignTo: '',
        resolved: false,
        solution: '',
        submitProcessing: false,

        subjectOptions: [
          { text: "Hardware", value: "Hardware" },
          { text: "Software", value: "Software" },
          { text: "Infrastructure", value: "Infrastructure" },
          { text: "Networking", value: "Networking" },
          { text: "Other", value: "Other" }
        ],

        assignToOptions: [],

        priorityModal: false,
        showResolved: false,
        manageTicketID: '',

          // Ticket Table
        ticketData: {
          unassigned: {
            columns: [
              {
                colTitle: 'Description',
                sortBy: 'description'
              },
              {
                colTitle: 'Open Date',
                sortBy: 'openDate'
              },
              {
                colTitle: 'Name of Submitter',
                sortBy: 'nameSubmit'
              },
              {
                colTitle: 'Email of Submitter',
                sortBy: 'emailSubmit'
              }
            ],
            rowData: [],
            filterWords: [
              'description',
              'openDate',
              'nameSubmit',
              'emailSubmit'
            ]
          },
          assigned: {
            columns: [  
              {
                colTitle: 'Ticket Number',
                sortBy: 'id'
              },
              {
                colTitle: 'Subject',
                sortBy: 'subject'
              },
              {
                colTitle: 'Priority',
                sortBy: 'priority'
              },
              {
                colTitle: 'Resolved',
                sortBy: 'resolved'
              },
              {
                colTitle: 'Assigned To',
                sortBy: 'assignedTo'
              },
              {
                colTitle: 'Description',
                sortBy: 'description'
              }
            ],
            rowData: [],
            filterWords: [
              'id',
              'subject',
              'priority',
              'resolved',
              'assignedTo'
            ]
          }
        }
      }
    },

    methods: {

      clearTicketForm: function() {
        this.description = "";
        this.priority = 3;
        this.dateOpened = "";
        this.dateClosed = "";
        this.nameSubmit = "";
        this.emailSubmit = "";
        this.solution = "";

        this.manageTicketID = '';
        this.assignToOptions.splice(0);
        this.populateAssignTo();

      },

      resetTicketTable: function() {
        this.ticketData.assigned.rowData.splice(0);
      },

      resetUnassignedTicketTable: function() {
        this.ticketData.unassigned.rowData.splice(0);
      },

      populateAssignTo: async function() {
        let token = this.$store.getters.getToken;
        let query = "populate[0]=groups&populate[1]=role";
        let filter = [];
      
        try {
          let res = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
          for(let v = 0; v < res.data.length; v = v + 1){
            for(let i = 0; i < res.data[v].groups.length; i = i + 1) {
              if(res.data[v].groups[i].name == "IT"){
                filter.push(res.data[v]);
              }
            }
          }
        } catch(error) {
          window.scroll({top: 0, behavior: 'smooth'});
          this.p4 = true;
          return;
        }
        filter.forEach(selectData => {
          let obj = {};
          if(selectData.role.name != "Administrator"){
            obj["text"] = selectData.firstName + ' ' + selectData.lastName;
            obj["value"] = selectData.firstName + ' ' + selectData.lastName;
            this.assignToOptions.push(obj);
          }
        });
      },

      manageUnassignedTicket: function(data) {
        this.description = data.description;
        this.dateOpened = data.openDate;
        this.nameSubmit = data.nameSubmit;
        this.emailSubmit = data.emailSubmit;
        this.manageTicketID = data.id;

        document.getElementById("ticketForm").scrollIntoView({behavior: "smooth"});
      },

      manageTicket: async function(data) {
        let token = this.$store.getters.getToken;
        let query = "filters[id][$eq][0]="+data.id;
        this.manageTicketID = data.id;
        let response = '';
        try {
          response = await DatabaseLib.getRequest('strapiDatabase','it-tickets',token,query);
        } catch(error) {
          window.scroll({top: 0, behavior: 'smooth'});
          this.p5 = true;
          return;
        }

        this.description = response.data.data[0].attributes.description;
        this.subject = response.data.data[0].attributes.subject;
        this.priority = response.data.data[0].attributes.priority;
        this.dateOpened = response.data.data[0].attributes.openDate;
        this.dateClosed = response.data.data[0].attributes.closeDate;
        this.nameSubmit = response.data.data[0].attributes.nameSubmit;
        this.emailSubmit = response.data.data[0].attributes.emailSubmit;
        this.assignTo = response.data.data[0].attributes.assignedTo;
        this.resolved = response.data.data[0].attributes.resolved;
        this.solution = response.data.data[0].attributes.solution;        

        if(this.resolved) {
          document.getElementById('resolvedFalse').checked = false;
          document.getElementById('resolvedTrue').checked = true;
        } else {
          document.getElementById('resolvedTrue').checked = false;
          document.getElementById('resolvedFalse').checked = true;
        }
    
        document.getElementById("ticketForm").scrollIntoView({behavior: "smooth"});
      },

      populateUnassignedTicketData: async function() {
        let token = this.$store.getters.getToken;
        var query = '';

        try {
          query = "filters[queued][$eq][0]="+false;

          let response = '';
          response = await DatabaseLib.getRequest('strapiDatabase','it-tickets',token,query);
          
          this.resetUnassignedTicketTable();

          for(let i = 0; i < response.data.data.length; i = i + 1) {
            this.ticketData.unassigned.rowData.push({
              id: response.data.data[i].id,
              description: response.data.data[i].attributes.description,
              openDate: response.data.data[i].attributes.openDate,
              nameSubmit: response.data.data[i].attributes.nameSubmit,
              emailSubmit: response.data.data[i].attributes.emailSubmit
            })
          }
        } catch(error) {
          window.scroll({top: 0, behavior: 'smooth'});
          this.p3 = true;
          return;
        }
      },

      populateTicketData: async function() {
        let token = this.$store.getters.getToken;
        var query = '';

        try {
          if(this.showResolved) {
            query = "filters[resolved][$eq][0]="+true+"&filters[queued][$eq][1]="+true;
          } else {
            query = "filters[resolved][$eq][0]="+false+"&filters[queued][$eq][1]="+true;
          }
          this.resetTicketTable();
          let response = '';

          response = await DatabaseLib.getRequest('strapiDatabase','it-tickets',token,query);

          for(let i = 0; i < response.data.data.length; i = i + 1) {
            this.ticketData.assigned.rowData.push({
              id: response.data.data[i].id,
              subject: response.data.data[i].attributes.subject,
              priority: response.data.data[i].attributes.priority,
              resolved: response.data.data[i].attributes.resolved,
              assignedTo: response.data.data[i].attributes.assignedTo,
              description: response.data.data[i].attributes.description
            }) 
          }
        } catch(error) {
          window.scroll({top: 0, behavior: 'smooth'});
          this.p3 = true;
          return;
        }
      },

    //===========================================================================================
		//	Method Name	:	sanitizeInput
		//	
		//  Parameters
		//  Name									  Type      			Direction   Description
		//  ----------------------	--------------	---------		-------------------------------------
		//	content									string		    	IN		      Input to be sanitized for DB entry.
		//  Return Value						void			      n/a		      n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	
		//===========================================================================================
		sanitizeInput(content) 
		{
      let input = this.$sanitize(content);  
      input = input.replace(/&amp;/g, "&");
			return input;
    },

    setSubject: function(value) {
      this.subject = value;
    },

    setAssignTo: function(value) {
      this.assignTo = value;
    },

    submitTicket: async function() {
      this.submitProcessing = true;
      let token = this.$store.getters.getToken;

      this.description = this.sanitizeInput(this.description);
      this.priority = this.sanitizeInput(this.priority);
      this.nameSubmit = this.sanitizeInput(this.nameSubmit);
      this.emailSubmit = this.sanitizeInput(this.emailSubmit);
      this.solution = this.sanitizeInput(this.solution);
      
      if(this.dateClosed == null){
        this.dateClosed = new Date().toString();
      }
     
      let data = {
        data: {
          description: this.description,
          subject: this.subject,
          priority: this.priority,
          openDate: new Date(this.dateOpened),
          closeDate:new Date(this.dateClosed),
          nameSubmit: this.nameSubmit,
          emailSubmit: this.emailSubmit,
          assignedTo: this.assignTo,
          resolved: this.resolved,
          solution: this.solution,
          queued: true
        }
      }
    console.log(data.data.closeDate);    
      try {
        await DatabaseLib.putRequest('strapiDatabase','it-tickets',token,this.manageTicketID,data);

        window.scroll({top: 0, behavior: 'smooth'});
        this.p1 = true;
        this.clearTicketForm(); // Housekeeping

        // Resets Ticket Table after submiting new ticket
        this.populateTicketData();
        this.populateUnassignedTicketData();
        this.manageTicketID = '';
      } catch(error) {

        window.scroll({top: 0, behavior: 'smooth'});
        this.p2 = true;
        this.submitProcessing = false;
        return;
      }
      this.submitProcessing = false;
    }
  },

  watch: {
    showResolved: function() {
      this.populateTicketData();
    }

  },    

  mounted() {
    this.populateAssignTo();
    this.populateUnassignedTicketData();
    this.populateTicketData();
  }
}
</script>