import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

import NewWar from '../views/WAR-Reports/New-War.vue'
import ResetPasswordSelf from '../views/Reset-Password.vue'
import AssignContractData from '../views/Administration/AssignContractData.vue'
import CreateNewUser from '../views/Administration/New-User.vue'
import store from '../store'
import EditSavedWar from '../views/WAR-Reports/Edit-Saved-War.vue'
import SubmittedWar from '../views/WAR-Reports/Submitted-War.vue'
import SubmittedMonthly from '../views/WAR-Reports/Submitted-Monthly.vue'
import ViewEditUser from '../views/Administration/View-Edit-User-Page.vue'
import EditUser from '../views/Administration/Edit-User.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import EditHomepage from '../views/Administration/EditHomePage.vue'
import MyAccount from '../views/MyAccount.vue'
import EmployeeDirectory from '../views/Resources/EmployeeDirectory.vue'
import VisitorInformation from '../views/Administration/VisitorInformation.vue'
import SubmitSupportTicket from '../views/IT/SubmitSupportTicket.vue'
import SupportTicket from '../views/IT/SupportTicket.vue'
import AssetTracking from '../views/IT/AssetTracking.vue'
import QPRForm from '../views/Project-Overview/QPR-Form.vue'
import ViewQPR from '../views/Project-Overview/View-QPR.vue'
//import TimestampReport from '../views/Supervisor-Tools/TimestampReport.vue'
//import ResetUserPassword from '../views/IT/ResetPassword.vue'
import WARSubStatus from '../views/Administrator-Tools/WAR-Submission-Status.vue'

import axios from 'axios';
import { getEndpoint } from '@/assets/js/DatabaseLib.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    props: true
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/My-Account',
    name: 'My-Account',
    component: MyAccount,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/WAR-Reports/New-War',
    alias: ['/WAR-Reports/New-War/:id'],
    name: 'New-War',
    component: NewWar,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/WAR-Reports/Edit-Saved-War',
    name: 'Edit-Saved-War',
    component: EditSavedWar,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/WAR-Reports/Submitted-War',
    name: 'Submitted-War',
    component: SubmittedWar,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/WAR-Reports/Submitted-Monthly',
    name: 'Submitted-Monthly',
    component: SubmittedMonthly,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/ResetPassword',
    name: 'ResetPasswordSelf',
    component: ResetPasswordSelf
  },
  {
    path: '/AssignContractData',
    component: AssignContractData,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/New-User',
    name: 'New-User',
    component: CreateNewUser,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path:'/View-Edit-User-Page',
    name: 'View-Edit-User-Page',
    component: ViewEditUser,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/Edit-User/:id',
    name: 'Edit-User',
    component: EditUser,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/Edit-Homepage',
    name: 'Edit-Homepage',
    component: EditHomepage,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/Resources/Employee-Directory',
    name: 'Employee-Directory',
    component: EmployeeDirectory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Visitor-Information',
    name: 'Visitor-Information',
    component: VisitorInformation,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/IT/SubmitSupportTicket',
    name: 'SubmitSupportTicket',
    component: SubmitSupportTicket,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/IT/SupportTicket',
    name: 'SupportTicket',
    component: SupportTicket,
    meta: {
      requiresAuth: true,
      requiresIT: true
    }
  },
  {
    path: '/IT/AssetTracking',
    name: 'AssetTracking',
    component: AssetTracking,
    meta: {
      requiresAuth: true,
      requiresIT: true
    }
  },
  /*{
    path: '/IT/ResetPassword',
    name: 'ResetUserPassword',
    component: ResetUserPassword,
    meta: {
      requireAuth: true,
      requiresIT: true
    }
  },*/
  {
    path: '/Project-Overview/QPR-Form',
    name: 'QPRForm',
    component: QPRForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Project-Overview/View-QPR',
    name: 'ViewQPR',
    component: ViewQPR,
    meta: {
      requiresAuth: true,
      requiresSupervisor: true
    }
  },
  /*{
    path: '/Supervisor-Tools/TimestampReport',
    name: 'TimestampReport',
    component: TimestampReport,
    meta: {
      requiresAuth: true,
      requiresSupervisor: true
    }
  },*/
  {
    path: '/Administrator-Tools/WARSubmissionStatus',
    name: 'WARSubmissionStatus',
    component: WARSubStatus,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Sends get request to check if token is still valid. If it isn't, redirects to login page
async function checkToken(token) {
  let response = await axios.get(getEndpoint('strapiDatabase') + 'api/users/me', { headers: { Authorization: 'Bearer '+ token }})
  .then(response => {
    return response;
  })
  .catch(function (error) {
    return error.response.data.message;
  })

  if (typeof response == 'string')
  {
    store.dispatch('logout');
    router.push({ name: 'Login', params: { p2: true }});
  }
}

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {

      //--------------------------------------------------------
      // Check if Token is still valid
      //--------------------------------------------------------
      if(store.getters.getToken) {
        checkToken(store.getters.getToken);
      }

      //--------------------------------------------------------
      // Check Administration Role
      //--------------------------------------------------------
      if(to.matched.some(record => record.meta.requiresAdmin)) {
        if (store.getters.getGroups.includes('Administration')) {
          next()
          return
        }
        next('/')
      }

      //--------------------------------------------------------
      // Check Supervisor Role
      //--------------------------------------------------------
      if(to.matched.some(record => record.meta.requiresSupervisor)) {
        if (store.getters.getGroups.includes('Supervisor')) {
          next()
          return
        }
        next('/')
      }
      
      //--------------------------------------------------------
      // Check IT Role
      //--------------------------------------------------------
      if(to.matched.some(record => record.meta.requiresIT)) {
        if (store.getters.getGroups.includes('IT')) {
          next()
          return
        }
        next('/')
      }
      //-------------------------------------------------------


      next()
      return
    }
    next('/Login')
  } else {
    next()
  }

})

export default router
