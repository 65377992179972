var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Calibri: {
    normal: 'calibri.ttf',
    bold: 'calibrib.ttf',
    italics: 'calibrii.ttf',
    bolditalics: 'calibriz.ttf'
  }
}

const { logoString } =  require('/src/assets/js/logo.js');

function fileDownload(paramId, paramProjectTask, paramActivity, paramName, paramDate, paramDivision, action) {
    let activityList = '';
    
    if (paramActivity != null)
    {
      for(let x = 0; x < paramActivity.content[0].content.length; x = x + 1) {

        activityList = activityList + '•  ' + paramActivity.content[0].content[x].content[0].content[0].text + '\n';

        if(paramActivity.content[0].content[x].content.length > 1) {
          for(let y = 0; y < paramActivity.content[0].content[x].content[1].content.length; y = y + 1) {

            activityList = activityList + '\u200B\t○  ' + paramActivity.content[0].content[x].content[1].content[y].content[0].content[0].text + '\n';

            if(paramActivity.content[0].content[x].content[1].content[y].content.length > 1) {
              for(let z = 0; z < paramActivity.content[0].content[x].content[1].content[y].content[1].content.length; z = z + 1) {
                
                activityList = activityList + '\u200B\t\u200B\t□ ' + paramActivity.content[0].content[x].content[1].content[y].content[1].content[z].content[0].content[0].text + '\n';

              }
            }
          }
        }
      }
    }
    else
    {
      activityList = 'NA'
    }

    var weeklyPDF = {
        content: [
        { image: 'logo', width: 500, height: 75 },
        { text: '200 Clinton Ave Ste 600 | Huntsville, AL 35801', style: 'smolHeader' },
        { text: '256.327.3410 Office | QuadrusCorp.com', style: 'smolHeader' },
        { text: 'Weekly Activity Report (WAR)', style: 'header', margin: [0,10]},
        
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: [ '*', '*'],
    
            body: [
              [ { text: 'Employee Name', bold: true }, paramName],
              [ { text: 'Charge Line / PC Number\n(if applicable)', bold: true }, paramProjectTask ],
              [ { text: 'Division', bold: true }, paramDivision ],
              [ { text: 'Date Range', bold: true }, paramDate ],
            ]
          }
        },
        
        { text: 'Please list the tasks and status below:', bold: true, margin: [0, 12], decoration: 'underline' },
        { text: activityList }
      ],
      images: {
        logo: logoString
      },
      styles: {
            header: {
                fontSize: 16,
                bold: true,
                alignment: 'center'
            },
            smolHeader: {
                fontSize: 10,
                alignment: 'center'
            }
      },
      defaultStyle: {
        font: 'Calibri'
      },
      info: {
        title: 'WAR'
      }
    }

    if (action === 'Download') {
      let fileName = paramName + '_' + paramDate + '_WAR.pdf';
      pdfMake.createPdf(weeklyPDF).download(fileName);
    } else if (action === 'Preview') {
      pdfMake.createPdf(weeklyPDF).open();
    }

    return 0;
}

export { fileDownload }