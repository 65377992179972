<template>
<!-- Footer -->
<mdb-footer class="font-small pt-2" style="color: white; background-color: black; margin-top: 0 !important;">

  <mdb-row class="justify-content-end">
    <mdb-col col="4">
      <div class="footer-copyright text-center py-3">
        &copy; Quadrus Corporation, 2021
      </div>
    </mdb-col>
    <mdb-col col="4">
      <div class="align-middle text-right mr-5" style="padding-top: 1rem !important; padding-bottom: 1rem !important;">
        Software Version 2.0.5
      </div>
    </mdb-col>
  </mdb-row>

</mdb-footer>
<!-- Footer -->
</template>

<script>
import { mdbFooter,  mdbRow, mdbCol } from 'mdbvue';
export default {
name: 'FooterPage',
components: {
    mdbFooter,

    mdbRow,
    mdbCol
}
}
</script>