<!-------------------------------------------------------------------------------------------------
//		Component Name		:		QDataTable
//		
//		Props							:			rowData: 			Array
//													headers: 			Array
//													filterWords: 		Array
//													btnName: 			String
//													hasBtn: 			Boolean
//													hasEdit: 			Boolean
//													hasDelete: 			Boolean
//													method: 			Function
//
//		Emitted Events		:		use-method
//													edit
//													delete
//		
//		Description
//		---------------------------------------------------------------------------------------------
//		Datatable component with all the functionality of the MDBootstrap
//		Datatable in addition to the following custom functionalities:
//
//		*		Custom Button Column
//
//				Description:	Allows the user to create a column that contains a button
//											that can be clicked to execute a parent method by giving
//											it an object of the row data of the button as a parameter.
//	
//		*		Modify Column
//
//				Description:	Allows the user to create a column that contains an edit
//											and/or delete button for each row. One or both buttons can
//											be enabled based on the props that are enabled. Both buttons
//											return an object of the row data of the button as a parameter.
//											The parent is responsible for providing the method(s) edit
//											and/or delete will use.
//
//		To use QDataTable, the following props are required: rowData, headers, and filterWords.
//
//		MODIFICATION HISTORY:
//
//		QCR				RSE						DATE				DESCRIPTION
//		--------	------------	----------	---------------------------------------------------------
//		
-------------------------------------------------------------------------------------------------->

<template>
	<div class="p-3 mb-3" style="background-color: white;">

		<!-- Show Entries & Search Bar -->
		<mdb-row>
			<mdb-col>
				<mdb-row class="align-items-center">
					<mdb-col md="3" class="mt-3">Show entries</mdb-col>
							<mdb-col md="4">
								<mdb-select :options="entries" @getValue="getSelectValue" placeholder="10"/>
							</mdb-col>
						</mdb-row>
					</mdb-col>
				<mdb-col md="2" offsetMd="4">
				<mdb-input type="text" v-model="filter" placeholder="Search" />
			</mdb-col>
		</mdb-row>
		<!-- /Show Entries & Search Bar -->

		<!-- Data Table -->
		<mdb-tbl id="myTable" style="background-color: white; overflow-x: auto;" bordered>

			<!-- Table Header -->
			<mdb-tbl-head>
				<tr class="header">
					<th class="text-center" v-for="header in headers" :key="header.colTitle">
						<div v-if="header.sortBy != ''" @click="sort(header.sortBy)">
							<mdb-row class="m-0">
								<mdb-col />
								<mdb-col>
									{{header.colTitle}}</mdb-col><mdb-col class="text-right"><mdb-icon icon="sort" />
								</mdb-col>
							</mdb-row>
						</div>
						<div v-else>{{header.colTitle}}</div>
					</th>
					<th v-if="hasEdit || hasDelete" class="text-center">Modify</th>
				</tr>
			</mdb-tbl-head>
			<!-- /Table Header -->

			<!-- Table Body without Search Filter -->
			<mdb-tbl-body v-if="!filter">
				<!-- :key references an object that Vue uses to individually distinguish them. This means that an ID is mandatory in the rowData -->
				<tr v-for="item in sortedItems" :key="item[rowData['id']]">
					<td v-for="column in filterWords" :key="column" class="text-center" style="vertical-align: middle;">
						{{item[column]}}
					</td>
					<td v-if="hasBtn && !hasBtn2" class="text-center" style="vertical-align: middle;">
						<mdb-btn size="sm" class="btnSingleSize" style="min-width: 100%;" v-on:click="$emit('use-method', item)">{{btnName}}</mdb-btn>
					</td>
					<td v-if="hasBtn && hasBtn2" class="text-center" style="vertical-align: middle;">
						<mdb-btn size="sm" class="btnDoubleSize" v-on:click="$emit('use-method', item)">{{btnName}}</mdb-btn>
						<mdb-btn size="sm" class="btnDoubleSize" v-on:click="$emit('use-method-2', item)">{{btnName2}}</mdb-btn>
					</td>
					<td v-if="hasEdit && !hasDelete" class="text-center" style="veritcal-align: middle;">
						<mdb-btn size="sm" class="btnSingleSize" color="info" style="width: 100%;" v-on:click="$emit('edit', item)">{{editName}}</mdb-btn>
					</td>
					<td v-if="!hasEdit && hasDelete" class="text-center" style="veritcal-align: middle;">
						<mdb-btn size="sm" class="btnSingleSize" color="danger" style="width: 100%;" v-on:click="$emit('deleteInfo', item)" @click.native="deleteModalUnfil = true">{{deleteName}}</mdb-btn>
					</td>
					<td v-if="hasEdit && hasDelete" class="text-center" style="veritcal-align: middle;">
						<mdb-btn size="sm" class="btnDoubleSize" color="info" style="width: 100%;" v-on:click="$emit('edit', item)">{{editName}}</mdb-btn>
						<mdb-btn size="sm" class="btnDoubleSize" color="danger" style="width: 100%;" v-on:click="$emit('deleteInfo', item)" @click.native="deleteModalUnfil = true">{{deleteName}}</mdb-btn>
					</td>
				</tr>
			</mdb-tbl-body>
			<!-- /Table Body without Search Filter -->

			<!-- Delete Button Modal Unfiltered -->
			<mdb-modal size="lg" centered :show="deleteModalUnfil" @close="deleteModalUnfil = false">
				<mdb-modal-header>
					<mdb-modal-title><p class="h2 red-text">WARNING!</p></mdb-modal-title>
				</mdb-modal-header>
				<mdb-modal-body>
					<slot></slot>
				</mdb-modal-body>
				<mdb-modal-footer class="d-flex justify-content-between">	
					<mdb-btn color="secondary" @click.native="deleteModalUnfil = false">Nevermind</mdb-btn>
					<mdb-btn color="danger" v-on:click="$emit('delete', item)" @click.native="deleteModalUnfil = false">{{deleteName}}</mdb-btn>
				</mdb-modal-footer>
			</mdb-modal>
			<!-- Delete Button Modal Unfiltered -->

			<!-- Table Body with Search Filter -->
			<mdb-tbl-body v-if="filter">
				<!-- :key references an object that Vue uses to individually distinguish them. This means that an ID is mandatory in the rowData -->
				<tr v-for="item in filteredItems" :key="item[rowData['id']]">
					<td v-for="column in filterWords" :key="column" class="text-center" style="vertical-align: middle;">
						{{item[column]}}
					</td>
					<td v-if="hasBtn && !hasBtn2" class="text-center" style="vertical-align: middle;">
						<mdb-btn size="sm" class="btnSingleSize" v-on:click="$emit('use-method', item)">{{btnName}}</mdb-btn>
					</td>
					<td v-if="hasBtn && hasBtn2" class="text-center" style="vertical-align: middle;">
						<mdb-btn size="sm" class="btnDoubleSize" v-on:click="$emit('use-method', item)">{{btnName}}</mdb-btn>
						<mdb-btn size="sm" class="btnDoubleSize" v-on:click="$emit('use-method-2', item)">{{btnName2}}</mdb-btn>
					</td>
					<td v-if="hasEdit && !hasDelete" class="text-center" style="veritcal-align: middle;">
						<mdb-btn size="sm" class="btnSingleSize" color="info" style="width: 100%;" v-on:click="$emit('edit', item)">{{editName}}</mdb-btn>
					</td>
					<td v-if="!hasEdit && hasDelete" class="text-center" style="veritcal-align: middle;">
						<mdb-btn size="sm" class="btnSingleSize" color="danger" style="width: 100%;" v-on:click="$emit('deleteInfo', item)" @click.native="deleteModalFil = true">{{deleteName}}</mdb-btn>
					</td>
					<td v-if="hasEdit && hasDelete" class="text-center" style="veritcal-align: middle;">
						<mdb-btn size="sm" class="btnDoubleSize" color="info" style="width: 100%;" v-on:click="$emit('edit', item)">{{editName}}</mdb-btn>
						<mdb-btn size="sm" class="btnDoubleSize" color="danger" style="width: 100%;" v-on:click="$emit('deleteInfo', item)" @click.native="deleteModalFil = true">{{deleteName}}</mdb-btn>
					</td>
				</tr>
			</mdb-tbl-body>
			<!-- /Table Body with Search Filter -->

			<!-- Delete Button Modal Filtered -->
			<mdb-modal size="lg" centered :show="deleteModalFil" @close="deleteModalFil = false">
				<mdb-modal-header>
					<mdb-modal-title><p class="h2 red-text">WARNING!</p></mdb-modal-title>
				</mdb-modal-header>
				<mdb-modal-body>
					<slot></slot>
				</mdb-modal-body>
				<mdb-modal-footer class="d-flex justify-content-between">	
					<mdb-btn color="secondary" @click.native="deleteModalFil = false">Nevermind</mdb-btn>
					<mdb-btn color="danger" v-on:click="$emit('delete', item)" @click.native="deleteModalFil = false">{{deleteName}}</mdb-btn>
				</mdb-modal-footer>
			</mdb-modal>
			<!-- /Delete Button Modal Filtered -->

			<!-- Table Footer -->
			<mdb-tbl-head>
				<tr class="header">
					<th class="text-center" v-for="header in headers" :key="header.colTitle">
						<div>{{header.colTitle}}</div>
					</th>
					<th v-if="hasEdit || hasDelete" class="text-center">Modify</th>
				</tr>
			</mdb-tbl-head>
			<!-- /Table Footer -->

		</mdb-tbl>
		<!-- /Data Table -->

		<!-- Pagination -->
		<mdb-row class="mb-3 align-items-center">

			<!-- Page Items without Search Filter -->
			<mdb-col v-if="!filter">
				Showing {{ currentPage * pageSize - (pageSize - 1) }} - {{ currentPage != numPages ? currentPage * pageSize : rowData.length }} ({{ rowData.length }})
			</mdb-col>
			<!-- /Page Items without Search Filter -->

			<!-- Page Items with Search Filter & Multiple Pages -->
			<mdb-col v-if="filter && filteredItems.length >= pageSize">
				Showing {{ currentPage * pageSize - (pageSize - 1) }} - {{ currentPage != numPages ? currentPage * pageSize : filteredItems.length }} ({{ numFilItems }})
			</mdb-col>
			<!-- /Page Items with Search Filter & Multiple Pages -->

			<!-- Page Items with Search Filter & Single Page -->
			<mdb-col v-if="filter && filteredItems.length < pageSize">
				Showing {{ currentPage * pageSize - (pageSize - 1) }} - {{ filteredItems.length }} ({{ filteredItems.length }})
			</mdb-col>
			<!-- /Page Items with Search Filter & Single Page -->

			<!-- Page Navigation -->
			<mdb-col md="6" offsetMd="1" class="text-right mr-4">
				<mdb-row class="align-items-center justify-content-end">
					<mdb-btn flat darkWaves @click="prevPage" class="tblBtn" id="prevBtn">Previous</mdb-btn>

					<!-- Page Numbers without Search Filter -->
					<mdb-row v-if="!filter">
						<span v-for="n in numPages" :key="n">
							<mdb-btn flat darkWaves v-if="n == currentPage" class="activePage m-0" @click="selectPage(n)">{{ n }}</mdb-btn>
							<mdb-btn flat darkWaves v-else-if="n < 10" class="inactivePage m-0" @click="selectPage(n)">{{ n }}</mdb-btn>
						</span>
						<span flat darkWaves v-if="numPages > 9" class="m-2">...</span>
					</mdb-row>
					<!-- /Page Numbers without Search Filter -->

					<!-- Page Numbers with Search Filter & One or More Pages -->
					<mdb-row v-if="filter && numFilteredPages >= 1">
						<span v-for="n in numFilteredPages" :key="n">
							<mdb-btn flat darkWaves v-if="n == currentPage" class="activePage m-0" @click="selectPage(n)">{{ n }}</mdb-btn>
							<mdb-btn flat darkWaves v-else-if="n < 10" class="inactivePage m-0" @click="selectPage(n)">{{ n }}</mdb-btn>
						</span>
						<span flat darkWaves v-if="numFilteredPages > 9" class="m-2">...</span>
					</mdb-row>
					<!-- /Page Numbers with Search Filter & One or More Pages -->

					<!-- Page Numbers with Search Filter & No Pages -->
					<mdb-row v-if="filter && numFilteredPages == 0">
						<mdb-btn flat darkWaves class="activePage m-0">1</mdb-btn>
					</mdb-row>
					<!-- /Page Numbers with Search Filter & No Pages -->

					<mdb-btn flat darkWaves @click="nextPage" class="tblBtn" id="nextBtn">Next</mdb-btn>
				</mdb-row>
			</mdb-col>
			<!-- /Page Navigation -->

		</mdb-row>
		<!-- /Pagination -->

	</div>

</template>

<script>
import { mdbTbl, mdbRow, mdbCol, mdbInput, mdbSelect, mdbBtn, mdbTblHead, mdbTblBody, mdbIcon, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter} from 'mdbvue';

export default 
{
	name: 'QDataTable',
	components: {
		mdbTbl,
		mdbRow,
		mdbCol,
		mdbTblHead,
		mdbTblBody,
		mdbIcon,
		mdbInput,
		mdbSelect,
		mdbBtn,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		mdbModalFooter
	},

	data() 
	{
		return {
			entries:											//Page Size Options
			[
				{ text: '10', value: 10 },
				{ text: '25', value: 25 },
				{ text: '50', value: 50 },
				{ text: '100', value: 100 }
			],
			filter:'',											// Current Search Filter
			currentSort: this.headers[0].sortBy,				// Default Sort By
			currentSortDir: 'asc',								// Default Sort Order
			pageSize: 10,										// Default Page Size
			numFilItems: 0,										// Number of Filtered Items
			currentPage: 1,										// Current Selected Page Number
			deleteModalUnfil: false,							// Modal for Delete Button disabled by default (w/o Filter)
			deleteModalFil: false,								// Modal for Delete Button disabled by default (w/ Filter)
			item: ''											// Prevents 'item' reference error
		}
	},

	props: 
	{
		rowData: Array,							// Row Data; Must be an Array of Objects
		headers: Array,							// Column Data; Must be an Array of Objects
		filterWords: Array,						// Searchable Columns; Must be an Array of Strings
		btnName: {								// Optional Button Name
			default: "Action",
			type: String
		},
		btnName2: {								// Optional Second Button Name
			default: "Action",
			type: String
		},				
		hasBtn: Boolean,						// Optional Button Column
		hasBtn2: Boolean,						// Optional Second Button Column
		editName: {								// Optional Edit Button Name
			default: "Edit",
			type: String
		},										
		hasEdit: Boolean,						// Optional Edit Button in Modify Column
		deleteName: {							// Optional Delete Button Name
			default: "Delete",
			type: String
		},										
		hasDelete: Boolean,						// Optional Delete Button in Modify Column
		method: { type: Function}				// Optional Method Passing
	},

	methods: 
	{
		//===========================================================================================
		//	Method Name	:	sort
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  sortId					String			IN			Id of the column to sort by
		//
		//	Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Sets the currentSort variable to the provided column id. If the column id is already the
		//	same as the currentSort variable, the sort direction is reversed.
		//===========================================================================================
		sort: function(sortId) 
		{
			//if s== current sort, reverse
			if (sortId === this.currentSort)
			{
				this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
			} else {
				this.currentSortDir = 'asc';
			}
			this.currentSort = sortId;
		},

		//===========================================================================================
		//	Method Name	:	nextPage
		//	
		//  Parameters
		//  Name					Type			Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//	Return Value			void			n/a				n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Increments current page if the number of data items is larger than the number currently
		//	displayed.
		//===========================================================================================
		nextPage: function() 
		{
			if ((this.currentPage*this.pageSize) < this.rowData.length) this.currentPage++;
		},

		//===========================================================================================
		//	Method Name	:	prevPage
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Decrements current page if the current page is not the first page.
		//===========================================================================================
		prevPage: function() 
		{
			if (this.currentPage > 1) this.currentPage--;
		},

		//===========================================================================================
		//	Method Name	:	selectPage
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//	n						int				IN			Page number to go to.
		//
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Sets the current page to the provided page number.
		//===========================================================================================
		selectPage: function(n) 
		{
			this.currentPage = n;
		},

		//===========================================================================================
		//	Method Name	:	getSelectValue
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//	value					int				IN			Number of rows to show on a page
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Sets the number of entries to show on a single page to the provided value.
		//===========================================================================================
		getSelectValue(value) 
		{
			this.pageSize = value;
		},

		//===========================================================================================
		//	Method Name	:	resetFilPages
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Resets the number of filtered pages to zero.
		//===========================================================================================
		resetFilPages() 
		{
			this.numFilItems = 0;
		},

		//===========================================================================================
		//	Method Name	:	incrementFilPages
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Increments the number of filtered pages by one.
		//===========================================================================================
		incrementFilPages() 
		{
			this.numFilItems += 1;
		}
	},

	computed: 
	{
		//===========================================================================================
		//	Computed Value	:	sortedItems
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			Array			OUT			The array of sorted data items.
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Uses sort method to recursively alphabetically sort the row data and return a copy of
		//	the newly sorted array.
		//===========================================================================================
		sortedItems: function() 
		{
			return this.rowData.slice().sort((a,b) => 
			{
				let modifier = 1;
				let tempA = '';
				let tempB = '';
				if (this.currentSortDir === 'desc') modifier = -1;
		
				//sort date range field formats used
				let dateRegexG = /(\d{1,2})([/])(\d{1,2})([/])(\d{2}) - (\d{1,2})([/])(\d{1,2})([/])(\d{2})/;
				let dateRegexG3 = /(\d{4})([-/])(\d{1,2})([-/])(\d{1,2})/;
				
				if (a[this.currentSort] === null && b[this.currentSort] === null) {
					return 0;
				} else if (a[this.currentSort] === null) {
					return -1* modifier;
				} else if (b[this.currentSort] === null) {
					return 1* modifier;
				}

				else if(dateRegexG.test(a[this.currentSort])){
					tempA = a[this.currentSort].split('-');
					tempB = b[this.currentSort].split('-');
					// get year, month and day into number format not string
					let getSeparateValA = tempA[0].split('/');
					let getSeparateValB = tempB[0].split('/');

					if(getSeparateValA[0].length == 1){getSeparateValA[0] = "0" + getSeparateValA[0];}
					if(getSeparateValA[1].length == 1){getSeparateValA[1] = "0" + getSeparateValA[1];}
					if(getSeparateValB[0].length == 1){getSeparateValB[0] = "0" + getSeparateValB[0];}
					if(getSeparateValB[1].length == 1){getSeparateValB[1] = "0" + getSeparateValB[1];}
					
					let numberDateA = getSeparateValA[2] + getSeparateValA[0] + getSeparateValA[1];
					let numberDateB = getSeparateValB[2] + getSeparateValB[0] + getSeparateValB[1];
					
					if(parseInt(numberDateA.replace(' ', '')) < parseInt(numberDateB.replace(' ', ''))){return -1 * modifier;}
					if(parseInt(numberDateA.replace(' ', '')) > parseInt(numberDateB.replace(' ', ''))){return 1 * modifier;}
				} else if(dateRegexG3.test(a[this.currentSort]) || a[this.currentSort] == null){
					
					let getSeparateValA = a[this.currentSort].split('-');
					let getSeparateValB = b[this.currentSort].split('-');
			
					if(getSeparateValA[1].length == 1){getSeparateValA[0] = "0" + getSeparateValA[0];}
					if(getSeparateValA[2].length == 1){getSeparateValA[1] = "0" + getSeparateValA[1];}
					if(getSeparateValB[1].length == 1){getSeparateValB[0] = "0" + getSeparateValB[0];}
					if(getSeparateValB[2].length == 1){getSeparateValB[1] = "0" + getSeparateValB[1];}
					
					let numberDateA = getSeparateValA[0] + getSeparateValA[1] + getSeparateValA[2];
					let numberDateB = getSeparateValB[0] + getSeparateValB[1] + getSeparateValB[2];
					
					if(parseInt(numberDateA.replace(' ', '')) < parseInt(numberDateB.replace(' ', ''))){return -1 * modifier;}
					if(parseInt(numberDateA.replace(' ', '')) > parseInt(numberDateB.replace(' ', ''))){return 1 * modifier;}
			
				} 
				else if(!isNaN(a[this.currentSort]) && !isNaN(parseFloat(a[this.currentSort])) && !isNaN(b[this.currentSort]) && !isNaN(parseFloat(b[this.currentSort]))){
					if(parseInt(a[this.currentSort]) < parseInt(b[this.currentSort])){return -1 * modifier;}
					if(parseInt(a[this.currentSort]) > parseInt(b[this.currentSort])){return 1 * modifier;}
				} else {
					try{
						if (a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()){return -1 * modifier;}
						if (a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
					} catch {
						if (a[this.currentSort] < b[this.currentSort]){return -1 * modifier;}
						if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					}
				}
				return 0;
			})
			.filter((row, index) => 
			{
				let start = (this.currentPage-1)*this.pageSize;
				let end = this.currentPage*this.pageSize;
				if (index >= start && index < end) return true;
			});
		},

		//===========================================================================================
		//	Computed Value	:	numPages
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			int				OUT			The number of pages.
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Computes the number of pages based on the number of data items and current page size.
		//	If there are no data items, it defaults at 1.
		//===========================================================================================
		numPages: function() 
		{
			if (this.rowData.length == 0) 
			{
				return 1;
			}
			else return Math.ceil(this.rowData.length / this.pageSize);
		},

		//===========================================================================================
		//	Computed Value	:	numFilteredPages
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			int				OUT			The number of filtered pages.
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Computes the number of filtered pages based on the number of filtered data items and
		//	current page size.
		//===========================================================================================
		numFilteredPages: function() 
		{
			return Math.ceil(this.numFilItems / this.pageSize);
		},

		//===========================================================================================
		//	Computed Value	:	filteredItems
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			Array			OUT			The array of filtered data items.
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Recursively filters the row data based on the current filter by going through the row
		//	data and returning items that include the filter. Items are then sorted.
		//===========================================================================================
		filteredItems: function() 
		{
			this.resetFilPages();	
			return this.rowData.slice().filter(item => 
			{
				let searchTerm = this.filter.toLowerCase();
				
				// The following loop uses the filter words passed from the parent to check if any item
				// property contains the search filter. I.E) If the filter words are ['id', 'name'],
				// the loop will check if item.id or item.name contains the given search filter.
				
				for (let i = 0; i < this.filterWords.length; i++)
				{
					if(item[this.filterWords[i]]){
						if (item[this.filterWords[i]].toString().toLowerCase().includes(searchTerm))
						{
							this.incrementFilPages();
							return true;
						}
					}
				}

				return false;
			})
			.sort((a,b) => 
			{
				let modifier = 1;
				if (this.currentSortDir === 'desc') modifier = -1;
				if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
				if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
				return 0;
			})
			.filter((row, index) => 
			{
				let start = (this.currentPage-1)*this.pageSize;
				let end = this.currentPage*this.pageSize;
				if (index >= start && index < end) return true;
			});
		}
	},

	watch: 
	{
		//===========================================================================================
		//	Watch Value	:	currentPage
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Each time the current page is updated, the previous and next buttons are enabled or
		//	disabled accordingly.
		//===========================================================================================
		currentPage: function() 
		{
			if (this.currentPage == 1) 
			{
				document.getElementById("prevBtn").disabled = true; 
				if (this.numPages > 1 && this.filter == '') 
				{
					document.getElementById("nextBtn").disabled = false;
				}
			}
			else if (this.currentPage == this.numPages || (this.currentPage == this.numFilteredPages && this.filter != '')) 
			{
				document.getElementById("nextBtn").disabled = true;
				document.getElementById("prevBtn").disabled = false;
			}
			else 
			{
				document.getElementById("nextBtn").disabled = false;
				document.getElementById("prevBtn").disabled = false;
			}
		},

		//===========================================================================================
		//	Watch Value	:	numPages
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Each time the number of pages is updated, the next button is disabled or enabled based
		//	on the number of pages being 1 or greater.
		//===========================================================================================
		numPages: function() 
		{
			this.currentPage = 1;				//If number of pages changes, go to first page
			if (this.numPages <= 1) 
			{
				document.getElementById("nextBtn").disabled = true;
			} 
			else
			{
				document.getElementById("nextBtn").disabled = false;
			}
		},

		//===========================================================================================
		//	Watch Value	:	numFilteredPages
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Each time the number of filtered pages is updated, the next button is enabled or
		//	disabled based on the number of filtered pages being 1 or greater.
		//===========================================================================================
		numFilteredPages: function() 
		{
			if (this.numFilteredPages <= 1 && this.filter != '') 
			{
				document.getElementById("nextBtn").disabled = true;
			} 
			else
			{
				document.getElementById("nextBtn").disabled = false;
			}
		},

		//===========================================================================================
		//	Watch Value	:	filter
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Each time the filter is updated, the current page is reset to 1. If the filter becomes
		//	empty and the number of pages is greater than 1, the next button is enabled. If the
		//	filter being updated causes the number of filtered pages to become less than or equal
		//	to 1, the next button is disabled.
		//===========================================================================================
		filter: function() 
		{
			this.currentPage = 1;
			if (this.filter == '' && this.numPages > 1) 
			{
				document.getElementById("nextBtn").disabled = false;
			}
			if (this.numFilteredPages <= 1 && this.filter != '') 
			{
				document.getElementById("nextBtn").disabled = true;
			}
		},

		//===========================================================================================
		//	Watch Value	:	pageSize
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Each time the page size is updated, the current page is reset to 1. If a filter is
		//	active and the number of filtered pages is 1 or less, the next button is disabled.
		//===========================================================================================
		pageSize: function() 
		{
			this.currentPage = 1;
			if (this.numFilteredPages <= 1 && this.filter != '') 
			{
				document.getElementById("nextBtn").disabled = true;
			}
		}
	},

	mounted() 
	{
		//Previous Button disabled by default
		document.getElementById("prevBtn").disabled = true;

		//If no row data, Next Button disabled by default
		if (this.rowData.length == 0) 
		{
			document.getElementById("nextBtn").disabled = true;
		}

	}
}
</script>

<style>
#myTable tr.header, #myTable tr:hover 
{
	/* Add a grey background color to the table header and on hover */
	background-color: #f1f1f1;
}

.tblBtn 
{
	font-size: 0.9rem;
	text-transform: capitalize;
	padding-right: 1rem;
	padding-left: 1rem;
}

.tblBtn:hover 
{
	background-color: rgba(158, 158, 158, 0.2);
}

.activePage 
{
	background-color: #4285F4 !important;
	color: white !important;
	padding-right: 0.8rem;
	padding-left: 0.8rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-size: 0.9rem;
}

.inactivePage 
{
	padding-right: 0.8rem;
	padding-left: 0.8rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-size: 0.9rem;
}

.inactivePage:hover 
{
	background-color: rgba(158, 158, 158, 0.2);
}

.btnSingleSize
{
	font-size: 0.8em !important;
}

.btnDoubleSize
{
	font-size: 0.8em !important;
}

@media (min-width: 1200px)
{
	.btnSingleSize
	{
		font-size: 0.8em !important;
		width: 80%;
	}

	.btnDoubleSize
	{
		font-size: 0.8em !important;
		width: 40%;
	}
}
</style>

<!-------------------------------------------------------------------------------------------------
//		USER GUIDE
//
//		Object Structure for Required Props:
//		---------------------------------------------------------------------------------------------
//		
//		headers:
//		[
//			{
//				colTitle: String,
//				sortBy: String
//			}
//		]
//		
//		rowData:
//		[
//			{
//				field: String,
//				field: String,
//				field: String,
//				...
//			}
//		]
//
//		filterWords:
//		[
//			'filterId',
//			'filterId',
//			'filterId',
//			...
//		]
//
//		Requirements for Props:
//		---------------------------------------------------------------------------------------------
//
//		* "sortBy" must be the name of the row field it corresponds too.
//			Example:
//			If a row object has the field 'id', the column for 'id' must define sortBy as 'id'.
//
//		* "filterWords" must contain the names of the row fields that the user wants to filter.
//			Example:
//			If a row object has the fields 'id' and 'name', filterWords must contain 'id' and 'name'
//			to be able to filter off these fields.
//
//		Optional Props Guide
//		---------------------------------------------------------------------------------------------
//
//		*		Custom Buttons Column
//
//				The optional button names are set by the props btnName and btnName2. Both default to "Action".
//				The buttons call the methods provided in @use-method and @use-method-2.
//				Buttons column is enabled by the prop hasBtn and/or hasBtn2.
//				User can choose to use only one or two buttons as needed.
//
//				Returns: Object with all the properties of the row.
//	
//		*		Modify Column
//
//				The edit button calls the method provided in @edit.
//				The optional edit button name is set by the prop editName. Defaults to "Edit".
//				The initial delete button calls the method provided in @deleteInfo.
//				The delete button within the delete modal calls the method provided in @delete.
//				The optional delete button name is set by the prop deleteName. Defaults to "Delete".
//				The modify column is enabled by the prop(s) hasEdit and/or hasDelete.
//
//				Returns: Object with all the properties of the row.
//		
//		*		Delete Modal
//
//				In order to put custom content inside of the Delete Modal, first enable the delete
//				button with hasDelete and insert your content like so:
//
//				<QDataTable 
//					:filterWords="this.filters" 
//					:rowData="this.rows"
//					:headers="this.cols"
//					:hasDelete="true"
//					@delete="YourDeleteMethod"
//					@deleteInfo="YourDeleteInfoMethod"
//				>
//					<Your Custom Content Here>
//				</QDataTable>
//
//				In order to use information from the selected row inside of the deleteModal, you
//				must use a method with @deleteInfo.
//
//		Example Uses:
//		---------------------------------------------------------------------------------------------
//
//		Using All Props:
//		<QDataTable 
//			:filterWords="this.filters" 
//			:rowData="this.rows" 
//			:headers="this.cols" 
//			:btnName="'YourButtonName'"
//			:editName="'YourEditName'"
//			:deleteName="'YourDeleteName'"
//			:hasBtn="true"
//			:hasBtn2="true"
//			:hasEdit="true"
//			:hasDelete="true" 
//    	@use-method="YourMethod"
//			@use-method-2="YourMethod2"
//			@edit="YourEditMethod"
//			@delete="YourDeleteMethod" 
//			@deleteInfo="YourDeleteInfoMethod"
//		/>
//
//		Using Only Modify Column with Edit Action:
//		<QDataTable
//			:filterWords="this.filters" 
//			:rowData="this.rows"
//			:headers="this.cols"
//			:hasEdit="true"
//			:editName="YourEditName"
//			@edit="editMethod" 
//		/>
//		
//		Using Only Required Props:
//		<QDataTable 
//			:filterWords="this.filters" 
//			:rowData="this.rows"
//			:headers="this.cols"
//		/>
-------------------------------------------------------------------------------------------------->