<template>
  <div class="page-start">
    <mdb-container fluid class="mt-4">
      <mdb-row>
        <mdb-col class="pr-0" col="10" offset="1">
          <mdb-card class="cards h-100">
            <mdb-card-body>
              <mdb-card-title class="black white-text p-2">SPECIAL ANNOUNCEMENT</mdb-card-title>
              <mdb-card-text style="text-align: left">
								<h3> Quadrus Family, </h3>
                <p>As most of you know, there was an EF-4 tornado that passed through Mississippi on Friday, March 24th.
                Our own Ryan Anderson’s hometown, Rolling Fork, was devastated by this event. Ryan went to Rolling
                Fork to check on his community for the past few days and will be traveling back and forth over the
                coming weeks/months to help clean up and support his family and neighbors.
                </p>
                <p>
                If you would like to help support Ryan and his efforts in his hometown, here are a couple of options:
                We have procured a list of items that Rolling Fork needs as they work through the destruction. We will
                have a box set up in the lobbies of both the Downtown corporate office and Pulaski facility where
                anyone who feels led to donate can leave their contributions.
                </p> 
                <ul>
                  <mdb-row>
                  <mdb-col>
                    <li>Lighter fluid</li> 
                    <li>Dishwashing liquid</li> 
                    <li>Laundry detergent</li>
                    <li>Bleach</li>
                    <li>Tampons</li>
                    <li>4T-5T pull ups</li> 
                    <li>Canned goods/Bread</li>
                    <li>Paper products (toilet paper, paper, plates)</li>
                    <li>Towels</li>
                  </mdb-col>
                  <mdb-col>
                    <li>Heavy duty trash bags</li>
                    <li>Diaper cream</li>
                    <li>Towels</li>
                    <li>Flatware</li>
                    <li>Adult diapers</li>
                    <li>Baby wipes</li>
                    <li>Infant cereal</li>
                    <li>New clothes with tags</li>
                  </mdb-col>
                  </mdb-row>
                </ul>
                <p>If you would rather send a monetary donation, there are two available options for you to do so. You can
                either donate money directly to the local bank in charge of the fund in Rolling Fork, or you can elect to
                donate through a payroll deduction. The link to the local bank with more information on sending
                donations directly to the fund is below:</p>
                <div style="text-align: center !important">
                  <a  href="https://www.bankofanguilla.com/donations-disaster-relief/?fbclid=IwAR1UsIyQI2Z9l7WJQ6dMuXpXUKJg1BqGDBNWQ1bBmtDeDap79yJdk6VJAPA" target="_blank">Donations – Disaster Relief | Bank of Anguilla</a>
                </div>
                <br><p>If you choose to donate through a payroll deduction, please email Jay Laughlin,
                jlaughlin@QuadrusCorp.com, to inform him of the amount you wish to donate and to serve as
                authorization for the payroll deduction. Quadrus will send a lump-sum payment to the local bank on
                behalf of all employees who elect to donate through a payroll deduction.
                Thank you to everyone for taking the time to read this and your consideration on extending aid to Ryan
                and his hometown.</p>
							</mdb-card-text>
              <mdb-carousel class="carousel-image" style="background-color: #000000" :items="specialAnnouncement"  slide :interval="8000" indicators controlls></mdb-carousel>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
      <br>
      <mdb-row>
        <mdb-col class="pr-0" col="10" offset="1">
          <iframe class="d-none d-sm-block d-md-block" src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FChicago&showTitle=0&showPrint=0&showTabs=0&showCalendars=0&src=ODJlZmJvdG91MHRjcGFoODI1NnR2ZW80b29AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%238E24AA&color=%230B8043" style="border:solid 1px #777; position: relative !important; width: 100% !important; height: 43.75em !important;" frameborder="0" scrolling="no"></iframe>
        </mdb-col>
      </mdb-row>
      <br>
      <mdb-row>
        <mdb-col class="pr-0" col="5" offset="1">
          <mdb-card class="cards h-100">
            <mdb-card-body>
              <mdb-card-title class="black white-text p-2">Employee Spotlight</mdb-card-title>
							<img :src="spotlightPhoto" class="rounded-circle img-fluid pb-2" style="height: 250px"/>
              <mdb-card-text>
								<h3>{{ spotlightTitle }}</h3><br>
								{{ spotlightDesc }}
							</mdb-card-text> 
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col class="pr-0" col="5">
          <mdb-card class="cards h-100">
            <mdb-card-body>
              <mdb-card-title class="black white-text p-2">Book Of The Month</mdb-card-title>
							<img :src="bookOfTheMonthPhoto" class="img-fluid pb-2" style="height: 250px"/>
              <mdb-card-text>
								<h3>{{ bookOfTheMonthTitle }}</h3><br>
								{{ bookOfTheMonthDesc }}
							</mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
      <br>
      <mdb-row class="container-fluid justify-content-center m-0">
        <mdb-col col="12 carousel-contain d-block d-md-none m-0">
          <mdb-carousel class="carousel-image" style="background-color: #000000" :items="basicCarousel" :interval="8000" indicators controlls></mdb-carousel>
        </mdb-col>
        <mdb-col col="10 carousel-contain d-none d-md-block m-0">
          <mdb-carousel class="carousel-image" style="background-color: #000000" :items="basicCarousel" :interval="8000" indicators controlls></mdb-carousel>
        </mdb-col>
      </mdb-row>
      <br>
    </mdb-container>
  </div>
</template>

<style>
  div.carousel-inner img {
    height: 90vh !important;
    max-width: 1516px !important;
    object-fit: contain !important;
  }
  @media screen and (max-width: 800px) {
    div.carousel-inner img {
      height: 50vh !important; 
    }
    .carousel-contain{
      width: 100% !important;
    }
  }
</style>

<script>
  //import axios from 'axios';
	import * as DatabaseLib from '@/assets/js/DatabaseLib.js';
  import {
    mdbCarousel,
    mdbRow,
    mdbCol,
    mdbContainer,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText
  } from "mdbvue";

export default {
  name: 'Home-Export',
  components: {
    mdbCarousel,
    mdbRow,
    mdbCol,
    mdbContainer,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText
  },
  data() {
    return {
      basicCarousel: [],
      specialAnnouncement: [],
			spotlightTitle: '',
			spotlightDesc: '',
			spotlightPhoto: '',
			bookOfTheMonthTitle: '',
			bookOfTheMonthDesc: '',
			bookOfTheMonthPhoto: ''
    }
  },

	methods: 
	{

    //===========================================================================================
		//	Method Name	:	populateHome
		//	
		//  Parameters
		//  Name										Type						Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//  Return Value            void            n/a         n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Fetches the homepage media and section information and populates the data() object
		//===========================================================================================
		populateHome: async function()
		{
			let token = this.$store.getters.getToken;
      let builtQuery = "sort[0]=id:asc&populate[1]=spotlightPhoto&populate[2]=bookOfTheMonthPhoto&populate[3]=carousel";
			let response = await DatabaseLib.getRequest('strapiDatabase','home-pages',token, builtQuery);
			this.spotlightTitle = response.data.data[0].attributes.spotlightTitle;
			this.spotlightDesc = response.data.data[0].attributes.spotlightDescription;
			this.spotlightPhoto = DatabaseLib.getEndpoint('strapiDatabase').slice(0, -1) + response.data.data[0].attributes.spotlightPhoto.data.attributes.url;
			this.bookOfTheMonthTitle = response.data.data[0].attributes.bookOfTheMonthTitle;
			this.bookOfTheMonthDesc = response.data.data[0].attributes.bookOfTheMonthDesc;
			this.bookOfTheMonthPhoto = DatabaseLib.getEndpoint('strapiDatabase').slice(0, -1) + response.data.data[0].attributes.bookOfTheMonthPhoto.data.attributes.url;

      for (let i = 0; i < response.data.data[0].attributes.carousel.data.length; i = i + 1) {
        this.basicCarousel.push({img: true, src: DatabaseLib.getEndpoint('strapiDatabase').slice(0, -1) + response.data.data[0].attributes.carousel.data[i].attributes.url});
      }

      /*for (let i = 0; i < response.data.data[0].attributes.carousel.data.length; i = i + 1) {
        this.specialAnnouncement.push({img: true, src: DatabaseLib.getEndpoint('strapiDatabase').slice(0, -1) +  response.data.data[0].attributes.carousel.data[i].attributes.url});
      }*/
		}
	},

	mounted() 
	{
		this.populateHome();
	}
};
</script>

<style>
@media (max-width: 550px) {
  .big-container {
      display: none;
  }
}
@media (min-width: 550px) {
  .small-container {
      display: none;
  }
}

.nav-link {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  color: white !important;
}
</style>
