<template>
<div class='page-start'>
	<mdb-container class="pb-3" fluid>
      <!-- User Feedback and Error Code Displays -->
			<mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p1" @closeAlert="p1=false" dismiss>
				Success! Submitted Charge Line!
			</mdb-alert>
			<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
				There was an error submitting Charge Line. Please try again! Error Code P2
			</mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p5" @closeAlert="p5=false" dismiss>
				Could not update table properly. Please refresh the page to see edits! Error Code P5
			</mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p6" @closeAlert="p6=false" dismiss>
				Could not remove charge line properly. Please try again! Error Code P6
			</mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p7" @closeAlert="p7=false" dismiss>
				Something went wrong. Please try again! Error code P7
			</mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p8" @closeAlert="p8=false" dismiss>
				Could not remove assigned charge lines properly. Please try again! Error Code P8
			</mdb-alert>
      <mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p9" @closeAlert="p9=false" dismiss>
				Success! Disabled Contract Data!
			</mdb-alert>
      <mdb-alert id="feedback-success" class="mt-3" color="info" v-if="p10" @closeAlert="p10=false" dismiss>
			</mdb-alert>
      <mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p14" @closeAlert="p14=false" dismiss>
				Success! Enabled Contract Data!
			</mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p15" @closeAlert="p15=false" dismiss>
				Could not enable charge line properly. Please try again! Error Code P15
			</mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p16" @closeAlert="p16=false" dismiss>
				Could not populate disabled contracts table. Please try again! Error Code P16
			</mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p17" @closeAlert="p17=false" dismiss>
        There was an error fetching assign user data. Please try again! Error Code P17
      </mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p18" @closeAlert="p18=false" dismiss>
        There was an error fetching contract data. Please try again! Error Code P18
      </mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p19" @closeAlert="p19=false" dismiss>
        There was an error fetching supervisor data. Please try again! Error Code P19
      </mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p20" @closeAlert="p20=false" dismiss>
        There was an error fetching unassign user data. Please try again! Error Code P20
      </mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p21" @closeAlert="p21=false" dismiss>
				There was an error submitting Charge Line. Missing a required field. Please try again! Error Code P21
			</mdb-alert>
      <!-- END User Feedback and Error Code Displays -->

      <!-- Create Charge Line Table -->
      <mdb-card class="mb-3 d-flex">
        <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">create charge line</h3>
        <div class="row mt-3 justify-content-center">
          <div class="col-12">
            <blockquote class="blockquote bq-primary mb-n3" v-if="editing">
              <p class="bq-title" >Editing {{selectedProject}}</p>
            </blockquote>
          </div>
        </div>
        <mdb-card-body>
          <form @submit.prevent="">
            <mdb-tbl>
              <mdb-tbl-head>
                <tr>
                  <th>Project</th>
                  <th>Division</th>
                  <th>Supervisor</th>
                  <th>Customer</th>
                  <th>Description</th>
                  <th>Billable</th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body>
                <tr>
                  <td>
                    <mdb-input basic class="mb-3" v-model="project" placeholder="PROJECT Example" type="text" />
                  </td>
                  
                  <td>
                    <mdb-input id="usernameDropdown" basic class="mb-3" v-model="division" placeholder="" type="text" />
                  </td>
                  <td>
                    <mdb-select id="supervisorDropdown" class="mt-n4" v-model="reportOptions" @getValue="setSupervisor" label="Supervisor"/>
                  </td>
                  <td>
                    <mdb-input basic class="mb-3" v-model="customer" placeholder="if applicable" type="text" />
                  </td>
                  <td>
                    <mdb-input basic class="mb-3" v-model="description" />
                  </td>
                  <td>
                    <template>
                      <select class="browser-default custom-select" v-model=billable>
                        <option value=true selected>Billable</option>
                        <option value=false>Non-Billable</option>
                      </select>
                    </template>
                    <!--- <mdb-input basic class="mb-3" type="checkbox" v-model="billable"/> --->
                  </td>
                </tr>
                <tr>
                  <td style="border: none !important;">
                    <div id="proValidation" style="display: none;">
                      <p style="color: red; font-size: 0.8em;" class="text-left">Please enter a project</p>
                    </div>
                  </td>
                  <td style="border: none !important;">
                    <div id="divValidation" style="display: none;">
                      <p style="color: red; font-size: 0.8em;" class="text-left">Please enter a division</p>
                    </div>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <!--<mdb-btn id="submitAddContract" class='QuadrusButton' v-on:click="submittedAddContract">Submit</mdb-btn>-->
            <mdb-btn size="block" class="mt-4" color="primary" type="submit" v-on:click="submittedAddContract" v-if="!submitProcessing">Submit</mdb-btn>
            <mdb-btn size="block" class="mt-4" color="primary" v-if="submitProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Processing...
            </mdb-btn>
            <br>
            <mdb-btn v-if="editing" color="danger" v-on:click="clearContractForm">Cancel Edit</mdb-btn>
          </form>
        </mdb-card-body>
      </mdb-card>
      <!-- END Create Charge Line Table -->

      <!-- Charge Lines Table -->
      <mdb-card class="mb-3">
        <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Charge Lines</h3>
        <mdb-card-body>
          
            <mdb-input type="checkbox" id="showDisable" name="showDisable" @change="populateDisabled" label="Display Disabled Charge Lines" />

            <QDataTable v-if="!showDisable" id='contractTable' :rowData="this.contractData.rowData" :filterWords="this.contractData.filterWords" :headers="this.contractData.columns" 
              :btnName="'ASSIGNMENT'" :hasBtn="true" :hasEdit="true" :deleteName="'Disable'" :hasDelete="true" @use-method="displayUserAssignment" 
              @edit="editContractData" @delete="disableProcess" @deleteInfo="contractDataInfo" >

              <h5><u>You are DISABLING {{ this.selectedProject }} </u></h5>
              <h5>Disabling a charge line will do the following:</h5>
              <hr>
                <ul class="text-left">
                  <li>It will remove that charge line from the "Charge Line" table as visible. You will need to filter to see them again.</li>
                  <li>It will unassign everybody from that charge line.</li>
                  <li>Users will NOT be able to submit WAR Reports on this charge line anymore.</li>
                </ul>
              <hr>
              <h5>Do you wish to continue?</h5>
            </QDataTable>

            <!-- TABLE TO DISPLAY DISABLED CHARGE LINES -------------------------------------------------------------------------------------------------------------------------------------------->
            <QDataTable v-if="showDisable" id='contractTable' :rowData="this.disableChargeLine.rowData" :filterWords="this.disableChargeLine.filterWords" :headers="this.disableChargeLine.columns" 
              :deleteName="'ENABLE'" :hasDelete="true" @delete="enableProcess" @deleteInfo="contractDataInfo" >

              <h5><u>You are ENABLING {{ this.selectedProject }} </u></h5>
              <h5>Enabling a charge line will do the following:</h5>
              <hr>
                <ul class="text-left">
                  <li>It will move the charge line to the active contract data list.</li>
                  <li>It will enable everybody from that charge line.</li>
                  <li>Users will be able to submit WAR Reports on this charge line.</li>
                </ul>
              <hr>
              <h5>Do you wish to continue?</h5>
            </QDataTable>
            <!------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
            
        </mdb-card-body>
      </mdb-card>
      <!-- END Charge Lines Table -->

      <!-- User Feedback and Error Code Displays -->
      <mdb-alert id="assignment-success" class="mt-3" color="success" v-if="p3" @closeAlert="p3=false" dismiss>
				Success! Submitted Charge Line Assignment Changes!
			</mdb-alert>
			<mdb-alert id="assignment-failure" class="mt-3" color="danger" v-if="p4" @closeAlert="p4=false" dismiss>
				There was an error submitting Charge Line Assignment Changes! Please try again!
			</mdb-alert>
      <!-- END User Feedback and Error Code Displays -->

      <!-- Assign user Table -->
      <mdb-card id="assignUserCard" class="mb-3" style="display: none;">
        <h3 class="card-header success-color-dark white-text text-center font-weight-bold text-uppercase py-4">Assign User to <a id="sourceProjectTo"></a></h3>
        <mdb-card-body>
          <mdb-input class="mt-0" v-model="search" label="Search by Name" />
          <mdb-datatable-2 id="userAssignTable" v-model="assignUserData" multiselectable @selected="assignUserSelected = $event" :searching="{value: search, field: 'name'}" />
          <div class="col-6 offset-3">
            <!--<mdb-btn color="primary" style="width: 100%;" v-on:click="assignUserSubmit">Submit</mdb-btn>-->
            <mdb-btn size="block" class="mt-4" color="primary" style="width: 100%;" type="submit" v-on:click="assignUserSubmit" v-if="!submitProcessing">Submit</mdb-btn>
            <mdb-btn size="block" class="mt-4" color="primary" style="width: 100%;" v-if="submitProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Processing...
            </mdb-btn>
          </div>
        </mdb-card-body>
      </mdb-card>
      <!-- END Assign user Table -->

      <!-- Unassign user Table -->
      <mdb-card id="unassignUserCard" style="display: none;">
        <h3 class="card-header danger-color-dark white-text text-center font-weight-bold text-uppercase py-4">Unassign User from <a id="sourceChargeLineFrom"></a></h3>
        <mdb-card-body>
          <mdb-input class="mt-0" v-model="search2" label="Search by Name" />
          <mdb-datatable-2 id="userUnassignTable" v-model="unassignUserData" multiselectable @selected="unassignUserSelected = $event" :searching="{value: search2, field: 'name'}" />
        </mdb-card-body>
        <div class="col-6 offset-3">
            <!--<mdb-btn color="primary" style="width: 100%;" v-on:click="unassignUserSubmit">Submit</mdb-btn>-->
            <mdb-btn size="block" class="mt-4" color="primary" style="width: 100%;" type="submit" v-on:click="unassignUserSubmit" v-if="!submitProcessing">Submit</mdb-btn>
            <mdb-btn size="block" class="mt-4" color="primary" style="width: 100%;" v-if="submitProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Processing...
            </mdb-btn>
        </div>
      </mdb-card>
      <!-- END Unassign user Table -->
  </mdb-container>
</div>
</template>

<script>
import { mdbInput, mdbTbl, mdbTblHead, mdbTblBody, mdbCard, mdbCardBody, mdbContainer, mdbBtn, mdbAlert, mdbSelect, mdbDatatable2 } from 'mdbvue';
import QDataTable from '@/components/QDataTable.vue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';

export default {
	name: 'AssignContractData',
	components: {
    mdbInput,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbCard,
    mdbCardBody,
    mdbContainer,
    mdbBtn,
    mdbAlert,
    mdbDatatable2,
    mdbSelect,
    QDataTable
	},
  data() {
    return {

      // New Contract Data
      project: "",
      oldProject: "",
      division: "",
      customer: "",
      description: "",
      reportsTo: "",
      reportOptions: [],
      billable: false,
      user: "",

      // Stored data when editing contract data
      selectedProject: '',
      selectedID: '',
      editing: false,

      // Alerts / Error Codes
      p1: false,  // Success on submitting charge line
			p2: false,  // Failure on submitting charge line
			p3: false,  // Success on assigning charge line
      p4: false,  // Failure on assigning charge line
      p5: false,  // Failure on updating table after editing charge line
      p6: false,  // Failure on deleting charge line from database
      p7: false,  // Failure on getting IDs from assigned contracts to disable
      p8: false,  // Failure on deleting assigned contracts 
      p9: false,  // Success on deleting contract data and assigned contracts
      p10: false, // submission processing spinner alert
      p14: false, // Success on enabling a charge line
      p15: false, // Failure on enabling charge line
      p16: false, // Failure on populating disabled contracts table
      p17: false,
      p18: false,
      p19: false,
      p20: false,
      p21: false,

      // Charge Line for Assignment
      assignProjectName: "",
      assignDivision: "",
      selectedData: "",
      search: '',
      search2: '',

      // Disable Modal
      //disableModal: false,
      //disableConfirm: false,

      // Show Disable Checkbox
      showDisable: false,

      // submission processing flag
      submitProcessing: false,

      // Charge Line Table
      contractData: {
        columns: [  
            {
                colTitle: 'Project',
                sortBy: 'project'
            },
            {
                colTitle: 'Division',
                sortBy: 'division'
            },
            {
                colTitle: 'Supervisor',
                sortBy: 'supervisor'
            },
            {
                colTitle: 'Customer',
                sortBy: 'customer'
            },
            {
                colTitle: 'Billable',
                sortBy: 'billable'
            },
            {
                colTitle: 'Action',
                sortBy: ''
            }
        ],
        rowData: [],
        filterWords: [
            'project',
            'division',
            'supervisor',
            'customer',
            'billable'
        ]
      },

      disableChargeLine: {
        columns: [
            {
                colTitle: 'Project',
                sortBy: 'project'
            },
            {
                colTitle: 'Division',
                sortBy: 'division'
            },
            {
                colTitle: 'Supervisor',
                sortBy: 'supervisor'
            },
            {
                colTitle: 'Customer',
                sortBy: 'customer'
            },
            {
                colTitle: 'Billable',
                sortBy: 'billable'
            }
        ],
        rowData: [],
        filterWords: [
            'project',
            'division',
            "supervisor",
            'customer',
            'billable'
        ]
      },

      // Assign User Table
      assignUserSelected: null,
      assignUserData: {
        columns: [
          {
            label: 'Name',
            field: 'name',
            sort: true
          },
          {
            label: 'Username',
            field: 'username',
            sort: true
          },
          {
            label: 'Email',
            field: 'email',
          }
        ],
        rows: []
      },

      // Unassign User Table
      unassignUserSelected: null,
      unassignUserData: {
        columns: [
          {
            label: 'Name',
            field: 'name',
            sort: true
          },
          {
            label: 'Username',
            field: 'username',
            sort: true
          },
          {
            label: 'Email',
            field: 'email',
          }
        ],
        rows: []
      }
    }      
  },

  methods: {

		//===========================================================================================
		//	Method Name	:	assignUserSubmit
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Submits form data from the "Assign user to" table to assign users to a charge line
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  displayUserAssignment(data)
		//===========================================================================================
    assignUserSubmit: async function() {
      this.submitProcessing = true;
      let token = this.$store.getters.getToken;
      let data = '';
      if(this.assignUserSelected != null){
        for (var i = 0; i < this.assignUserSelected.length; i = i + 1) {
          data = {
            data: {
              username: this.assignUserSelected[i].username,
              email: this.assignUserSelected[i].email,
              firstLastName: this.assignUserSelected[i].name,
              project: this.assignProjectName,
              division: this.assignDivision,
              disabled: false
            }
          }
          try {
          await DatabaseLib.postRequest('strapiDatabase','assigned-contracts',token,data);
          } 
          catch(error) 
          {
            window.scroll({top: 0, behavior: 'smooth'});
            this.p4 = true;
            this.submitProcessing = false;
            return;
          }
      }
        }
       

      // Sets the component data to unselect everything from the table. This prevents rows still being selected after submit.
      document.getElementById('userAssignTable').__vue__.selected = [];

      this.p3 = true;
      this.submitProcessing = false;
      this.displayUserAssignment(this.selectedData);
    },

		//===========================================================================================
		//	Method Name	:	clearContractForm
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Cleanly clears the "Create Charge Line" form data
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  None
		//===========================================================================================
    clearContractForm: function() {
      this.project = '';
      this.division = '';
      this.customer = '';
      this.reportsTo = '';
      this.description = '';
      this.billable = false;
      this.editing = false;

      this.reportOptions.splice(0);
      this.populateSupervisor();
    },

    //===========================================================================================
		//	Method Name	:	contractDataInfo
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
    //  ----------------------	--------------	---------		-------------------------------------
    //  data                    JSON object     IN          Holds the data of the selected charge line
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Sets the variables selectedProject, selectedTask, and selectedID to be displayed in the 
    //  disable modal.
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  None
		//===========================================================================================
    contractDataInfo: function(data) {
      this.selectedProject = data.project;
      this.selectedID = data.id;
    },

		//===========================================================================================
		//	Method Name	:	disableContractData
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
    //  ----------------------	--------------	---------		-------------------------------------
    //  data                    JSON object     IN          Holds the data of the selected charge line
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Calls the modal that requires confirmation on deleting a charge line
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  None
		//===========================================================================================
    disableContractData: function(data) {

      //******************* Clear other options ************************
      document.getElementById('assignUserCard').style.display = 'none';
      document.getElementById('unassignUserCard').style.display = 'none';
      this.clearContractForm;
      this.editing = false;
      //*****************************************************************

      this.selectedProject = data.project;
      this.selectedID = data.id

      //this.disableModal = true; // Enables the modal that pops up. This modal makes actual call to disableProcess
    },

		//===========================================================================================
		//	Method Name	:	disableProcess
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Disables the selected charge line from the contract data database and unassignes all users
    //  in assigned contracts assigned to that charge line.
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  None
		//===========================================================================================
    disableProcess: async function() {

      //this.disableeModal = false;
      //this.disableConfirm = false;

      let token = this.$store.getters.getToken;
      let query = "";
      if(this.selectedOldProject){
        query = "filters[project][$eq][0]=" + this.selectedOldProject;
      } else {
        query = "filters[project][$eq][0]=" + this.selectedProject;
      }
      console.log(this.selectedOldProject);
      console.log(query);
      let response =  await DatabaseLib.getRequest('strapiDatabase','contract-datas',token,query);
      console.log(response);
      this.selectedID = response.data.data[0].id;

      let data = {
        data: {
          disabled: true
        }
      }

      try {
        await DatabaseLib.putRequest('strapiDatabase','contract-datas',token,this.selectedID,data);
      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
        this.p6 = true;
        return;
      }

      try {
        let query = "filters[project][$eq][0]=" + encodeURIComponent(this.selectedProject) + "&filters[disabled][$eq][2]="+false;
        
        let assignedContracts = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query);

        for(let i = 0; i < assignedContracts.data.data.length; i = i + 1) {
          await DatabaseLib.putRequest('strapiDatabase','assigned-contracts',token,assignedContracts.data.data[i].id,data);
        }
      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
        this.p6 = true;
        return;
      }

      this.populateContracts(true);

      window.scroll({top: 0, behavior: 'smooth'});
      this.p9 = true;
    },

		//===========================================================================================
		//	Method Name	:	displayUserAssignment
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
    //  ----------------------	--------------	---------		-------------------------------------
    //  data                    JSON Object     IN          Holds the data from selected charge line
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Displays the assignment tables, clears the tables from the last population, and makes a 
    //  call to populate the tables
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  populateAssignmentTables(data)
		//===========================================================================================
    displayUserAssignment: async function(data) {

      if(data != this.selectedData) {
        document.getElementById('assignUserCard').style.display = 'block';
        document.getElementById('unassignUserCard').style.display = 'block';

        document.getElementById('assignUserCard').scrollIntoView({ behavior: 'smooth', block: 'start' });     
        document.getElementById('sourceProjectTo').innerHTML = data.project;
        document.getElementById('sourceChargeLineFrom').innerHTML = data.project;

        this.assignProjectName = data.project;
        this.assignDivision = data.division;
        this.selectedData = data;
      }

      // Clears assignment tables
      this.unassignUserData.rows.splice(0);
      this.assignUserData.rows.splice(0);

      await this.populateAssignmentTables(data);

    },

		//===========================================================================================
		//	Method Name	:	editContractData
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
    //  ----------------------	--------------	---------		-------------------------------------
    //  data                    JSON Object     IN          Holds the data from selected charge line
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Populates the "Create Charge Line" form with data from the selected charge line to edit.
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  None
		//===========================================================================================
    editContractData: function(data) {

      //******************* Clear other options ************************
      document.getElementById('assignUserCard').style.display = 'none';
      document.getElementById('unassignUserCard').style.display = 'none';

      //*****************************************************************

      // Stores data for future use when editing
      this.selectedProject = data.project;
      this.selectedID = data.id;

      window.scroll({top: 0, behavior: 'smooth'});
      this.editing = true;
      this.project = data.project;
      this.division = data.division;
      this.reportsTo = data.supervisor;
      this.billable = data.billable;

      // Make this better some day
      if (data.supervisor) 
      {
        let test = this.reportOptions.findIndex(i => i.value === data.supervisor);
        let obj = {};
        obj["text"] = this.reportOptions[test].text;
        obj["value"] = this.reportOptions[test].value;
        obj["selected"] = true;
        
        // Deletes the Option from the array
        this.reportOptions.splice(test, 1);
        
        // Puts option back in array as selected
        this.reportOptions.splice(test, 0, obj);
      }

      this.customer = data.customer;
      this.description = data.description;
    },

    //===========================================================================================
		//	Method Name	:	
		//	
		//  Parameters
		//  Name										Type						Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//  Return Value            void            n/a         n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Enables contract data and charge lines so they can be assigned to users and users can
    //  submit WAR Reports on them
		//===========================================================================================
    enableProcess: async function() {
      let token = this.$store.getters.getToken;
      let query = "";
      if(this.selectedOldProject){
        query = "filters[project][$eq][0]=" + this.selectedOldProject;
      } else {
        query = "filters[project][$eq][0]=" + this.selectedProject;
      }
			
      let response =  await DatabaseLib.getRequest('strapiDatabase','contract-datas',token,query);
      this.selectedID = response.data.data[0].id;

      let data = {
        data: {
          disabled: false
        }
      }

      // Enables selected charge line in the Contract Data table so it can be assigned to users
      try {
        await DatabaseLib.putRequest('strapiDatabase','contract-datas',token,this.selectedID,data);
      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
        return;
      }

      // Enables selected contract data so users can submit WR Reports on them again
      try {
        let query = "filters[project][$eq][0]=" + encodeURIComponent(this.selectedProject) + "&filters[disabled][$eq][2]="+true;
      
        let assignedContracts = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query);

        for(let i = 0; i < assignedContracts.data.data.length; i = i + 1) {
          await DatabaseLib.putRequest('strapiDatabase','assigned-contracts',token,assignedContracts.data.data[i].id,data);
        }
      } catch(error) {
        this.p15 = true;
        window.scroll({top: 0, behavior: 'smooth'});
        return;
      }

      // Housekeeping, refreshes tables
      this.populateDisabled(true);
      this.populateContracts(true);

      window.scroll({top: 0, behavior: 'smooth'});
      this.p14 = true;
    },

		//===========================================================================================
		//	Method Name	:	getLastContract
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Obtains the latest contract submitted to the contract data database and populates the 
    //  "charge lines" table with it
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  None
		//===========================================================================================
    getLastContract: async function() {
      let token = this.$store.getters.getToken;
      let response = await DatabaseLib.getRequest('strapiDatabase','contract-datas',token);
      var latestContract = response.data.data[response.data.data.length-1];
 
      this.contractData.rowData.push({
          project: latestContract.attributes.project,
          division: latestContract.attributes.division,
          supervisor: latestContract.attributes.supervisor,
          customer: latestContract.attributes.customer,
          description: latestContract.attributes.description,
          billable: latestContract.attributes.billable,
          id: latestContract.id
      })
    },

		//===========================================================================================
		//	Method Name	:	populateAssignmentTables
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
    //  ----------------------	--------------	---------		-------------------------------------
    //  data                    JSON Object     IN          Holds the data from selected charge line
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Makes calls to obtain users to populate the "Assign Users" and "Unassign Users" tables 
    //  and populates the tables.
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  populateUnassignUser(data)
    //  populateAssignUser(unassignUsers)
		//===========================================================================================
    populateAssignmentTables: async function(data) {
      let unassignUsers = await this.populateUnassignUser(data);
      let assignUsers = await this.populateAssignUser(unassignUsers);

      unassignUsers.data.data.forEach(checkUser => {
          this.unassignUserData.rows.push({
              name: checkUser.attributes.firstLastName,
              username: checkUser.attributes.username,
              email: checkUser.attributes.email,
              id: checkUser.id
          })
      });
      assignUsers.data.forEach(users => {
          this.assignUserData.rows.push({
            name: users.firstName+" "+users.lastName,
            username: users.username,
            email: users.email
          })
      });

    },

		//===========================================================================================
		//	Method Name	:	populateAssignUsers
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
    //  ----------------------	--------------	---------		-------------------------------------
    //  unassignUsers           JSON Object     IN          Holds the data for users already assigned 
    //                                                      to the selected charge line
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Builds and executes a query to obtain all the users NOT assigned to the selected charge line
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  None
		//===========================================================================================
    populateAssignUser: async function(unassignUsers) {

      var token = this.$store.getters.getToken;
      let buildQuery = 'filters[blocked][$eq][0]='+false + '&populate[$and][1]=role';
      
      // Builds a query to obtain all users NOT assigned to the charge line
      for (let i = 0; i < unassignUsers.data.data.length; i = i + 1)
      {
        buildQuery = buildQuery + '&filters[$and]['+(i+2)+'][username][$ne]=' + unassignUsers.data.data[i].attributes.username;
      }
      let response = '';
      try {
        response = await DatabaseLib.getRequest('strapiDatabase','users',token,buildQuery);
      } catch (error) {
        window.scroll({top: 0, behavior: 'smooth'});
        this.p17 = true;
        return;
      }
      return response;
    },

		//===========================================================================================
		//	Method Name	:	populateContracts
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
    //  ----------------------	--------------	---------		-------------------------------------
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Makes a call to the contract data database and populates the "Charge Lines" table
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  None
		//===========================================================================================
    populateContracts: async function(data) {
      if(data == true) {
        this.contractData.rowData.splice(0);
      }
      let query = "filters[disabled][$eq][0]=" + false;
      let response = '';
      try { 
        response = await DatabaseLib.getRequest('strapiDatabase','contract-datas',this.$store.getters.getToken, query);
      } catch (error) {
        window.scroll({top: 0, behavior: 'smooth'});
        this.p18 = true;
        return;
      }
      response.data.data.forEach(tableRow => {
          this.contractData.rowData.push({
            id: tableRow.id,
            project: tableRow.attributes.project,
            division: tableRow.attributes.division,
            supervisor: tableRow.attributes.supervisor,
            customer: tableRow.attributes.customer,
            description: tableRow.attributes.description,
            billable: tableRow.attributes.billable
        })
      })
    },

    //===========================================================================================
		//	Method Name	:	populateSupervisor
		//	
		//  Parameters
		//  Name										Type						Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//  Return Value            void            n/a         n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Fetches all users who are in the Supervisor goup and populates dropdown with their data
		//===========================================================================================
    populateSupervisor: async function() {
			let token = this.$store.getters.getToken;
			
      let query = "filters[blocked][$eq][0]="+false+"&populate[1]=groups&populate[2]=role";
      let response = '';      
      try {
        response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
        this.p19 = true;
        return;
      }
      let sup = [];
      for(let c = 0; c < response.data.length; c = c + 1){
        if(response.data[c].role.name != "Administrator"){
          for(let i = 0; i < response.data[c].groups.length; i = i + 1) {
            if(response.data[c].groups[i].name == "Supervisor"){
              sup.push(response.data[c]);
            }
          }
        }
      }
      
			sup.forEach(supervisor => {
				let obj = {};
				obj["text"] = supervisor.firstName + ' ' + supervisor.lastName;
				obj["value"] = supervisor.username;
				if (this.reportsTo === obj.value)
				{
					obj["selected"] = true;
				}
				this.reportOptions.push(obj);
			});
		},

    //===========================================================================================
		//	Method Name	:	
		//	
		//  Parameters
		//  Name										Type						Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//  Return Value            void            n/a         n/a
    //  data                    boolean         in          Determines if the Disabled table should
    //                                                      be shown
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Fetches disabled charge lines and populates the disabled charge line table. Makes a check
    //  if the disabled table should be shown.
		//===========================================================================================
    populateDisabled: async function(data) {
      
      // If the checkbox becomes unchecked, clear the disabled table
      if(data == true) {
        this.disableChargeLine.rowData.splice(0);
      }

      this.showDisable = data;

      var token = this.$store.getters.getToken;

      try {
        let query = "filters[disabled][$eq][0]=" + true;
        let disabled = await DatabaseLib.getRequest('strapiDatabase','contract-datas',token,query);
        for(let i = 0; i < disabled.data.data.length; i = i + 1) {
          this.disableChargeLine.rowData.push({
            id: disabled.data.data[i].id,
            project: disabled.data.data[i].attributes.project,
            division: disabled.data.data[i].attributes.division,
            supervisor: disabled.data.data[i].attributes.supervisor,
            customer: disabled.data.data[i].attributes.customer,
            description: disabled.data.data[i].attributes.description,
            billable: disabled.data.data[i].attributes.billable
          })
        }
      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
        this.p16 = true;
        return;
      }
    },

		//===========================================================================================
		//	Method Name	:	populateUnassignUser
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
    //  ----------------------	--------------	---------		-------------------------------------
    //  data                    JSON Object     IN          Holds the data from selected charge line
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Makes calls to assigned contracts to obtain users assigned to the selected charge
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  None
		//===========================================================================================
    populateUnassignUser: async function(data) {
      let token = this.$store.getters.getToken;
      let query = 'filters[project][$eq][0]='+encodeURIComponent(data.project);

      let response = '';
      try {
        response = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query);
      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
        this.p20 = true;
        return;
      }

      return response;
    },

    //===========================================================================================
		//	Method Name	:	sanitizeInput
		//	
		//  Parameters
		//  Name										Type						Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//	content									Any   					IN					Input to be sanitized for DB entry.
		//  Return Value						Any						  Out					Returned sanitized input
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Sanitizes form input and returns the sanitized data
		//===========================================================================================
		sanitizeInput(content) 
		{
      let input = this.$sanitize(content);
      input = input.replace(/&amp;/g, "&");
			return input;
    },
    
    //===========================================================================================
		//	Method Name	:	
		//	
		//  Parameters
		//  Name										Type						Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//  Return Value            void            n/a         n/a
    //  value                   string          in          holds the value from the dropdown list
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Receives the data value from the supervisor dropdown list and sets the form variable to
    //  the selected supervisor data
		//===========================================================================================
    setSupervisor: function(value) 
		{
			this.reportsTo = value;
		},

		//===========================================================================================
		//	Method Name	:	submittedAddContract
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
    //  ----------------------	--------------	---------		-------------------------------------
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	This function serves 2 purposes:
    //  1. If the user is editing an existing charge line, this function makes a call to the already 
    //  assigned contract data to update. It also updates the necessary information in the assigned
    //  contract database for the users assigned to the edited contract.
    //  2. If the user is adding a new contract, it adds the contract to the contract data database
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  clearContractForm()
    //  getLastContract()
		//===========================================================================================
    submittedAddContract: async function() {
      let pro = this.project;
      let div = this.division;
      this.submitProcessing = true;
      this.p10 = true;
      // This section is if the user is editing an existing contract
      this.project = this.sanitizeInput(this.project);
      this.division = this.sanitizeInput(this.division);
      this.customer = this.sanitizeInput(this.customer);
      this.description = this.sanitizeInput(this.description);
      this.reportsTo = this.sanitizeInput(this.reportsTo);

      if(this.editing == true) {

        let token = this.$store.getters.getToken;
        let query2 = "filters[project][$eq][0]=" + this.selectedProject;
        let response2 =  await DatabaseLib.getRequest('strapiDatabase','contract-datas',token,query2);
        if(response2.data.data.length > 0){this.selectedID = response2.data.data[0].id;}

        let data = {
          data: {
            project: this.project,
            division: this.division,
            customer: this.customer,
            description: this.description,
            supervisor: this.reportsTo,
            billable: this.billable,
          }
        };

        let putResponse = '';

        try{
          putResponse = await DatabaseLib.putRequest('strapiDatabase','contract-datas',token,this.selectedID,data);
        } catch(error) {
          window.scroll({top: 0, behavior: 'smooth'});
          this.p2 = true;
          this.p10 = false;
          this.submitProcessing = false;
          return;
        }

        // This implementaton sucks. The assigned contracts update portion needs to be reworked.
        // Checks to see if the Project name or Task name have changed. If so, update in Assigned Contracts
        if(this.project != this.selectedProject && putResponse != null) {
          let query = 'filters[project][$eq][0]='+encodeURIComponent(this.selectedProject);
          let responseID = '';
          try {
            responseID = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query);
          } catch(error) {
            window.scroll({top: 0, behavior: 'smooth'});
            this.p2 = true;
            this.p10 = false;
            this.submitProcessing = false;
            return;
          }
          
          let data = {
            data:{
              project: this.project,
            }
          }
     
          

          responseID.data.data.forEach(assignedContract =>{
            try {
              DatabaseLib.putRequest('strapiDatabase','assigned-contracts',token,assignedContract.id,data);
            } catch(error) {
              window.scroll({top: 0, behavior: 'smooth'});
              this.p2 = true;
              this.p10 = false;
              this.submitProcessing = false;
              return;
            }
          })
        }

        // This section will update the Charge Lines table with edited charge line
        //let updatedIndex = this.contractData.rowData.findIndex(con => con.id == this.selectedID);
        //let query = "filters[id][$eq][0]="+this.selectedID;

        try {
          /*let response = await DatabaseLib.getRequest('strapiDatabase','contract-datas',token,query);
          this.contractData.rowData[updatedIndex].project = response.data.data[0].attributes.project;
          this.contractData.rowData[updatedIndex].task = response.data.data[0].attributes.task;
          this.contractData.rowData[updatedIndex].division = response.data.data[0].attributes.division;
          this.contractData.rowData[updatedIndex].customer = response.data.data[0].attributes.customer;
          this.contractData.rowData[updatedIndex].description = response.data.data[0].attributes.description;
          this.contractData.rowData[updatedIndex].id = response.data.data[0].id;
          this.contractData.rowData[updatedIndex].supervisor = response.data.data[0].attributes.supervisor;
          this.contractData.rowData[updatedIndex].billable = response.data.data[0].attributes.billable;*/

          this.populateContracts(true);

          window.scroll({top: 0, behavior: 'smooth'});
          this.selectedData = '';
          this.selectedID = '';
          this.p1 = true;
        } catch(error) {
          window.scroll({top: 0, behavior: 'smooth'});
          this.p5 = true;
          this.p10 = false;
          this.submitProcessing = false;
          return;
        }

        this.editing = false;
        this.clearContractForm(); // Housekeeping

      } else if(this.project != '' && this.division != '') { 
        // This portion is if the user is NOT editing, but submitting new contract
        let token = this.$store.getters.getToken

        let data = {
          data: {
            project: this.project,
            division: this.division,
            customer: this.customer,
            description: this.description,
            supervisor: this.reportsTo,
            billable: this.billable,
            disabled: false
          }
        }
        
        try {
          await DatabaseLib.postRequest('strapiDatabase','contract-datas',token,data);
          
          window.scroll({top: 0, behavior: 'smooth'});
          this.p1 = true;
          this.clearContractForm(); // Housekeeping 
          //this.getLastContract();   // Update table with latest entry
          this.populateContracts(true);
        } catch(error) {
          window.scroll({top: 0, behavior: 'smooth'});
          this.p2 = true;
          this.p10 = false;
          this.submitProcessing = false;
          return;
        } 
      }
      
      if(pro == ''){
        this.p21 = true;
        document.getElementById('proValidation').style='display: block';
      } else {document.getElementById('proValidation').style='display: none'; this.p21 = false;}
      if(div == ''){
        this.p21 = true;
        document.getElementById('divValidation').style='display: block';
      } else {document.getElementById('divValidation').style='display: none'; this.p21 = false;}
             
      this.submitProcessing = false;
      this.p10 = false;
    },

		//===========================================================================================
		//	Method Name	:	unassignUserSubmit
		//	
    //  Parameters :
		//  Name										Type						Direction		Description
    //  ----------------------	--------------	---------		-------------------------------------
		//	Return Value						None						n/a					n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
    //	Makes a call to delete an assigned contract of the user unassigned from a charge line in
    //  the assigned contracts database
    //
    //  Function Calls
    //  -----------------------------------------------------------------------------------------
    //  displayUserAssignment(selectedData)
		//===========================================================================================
    unassignUserSubmit: async function() {
      this.submitProcessing = true;
      if(this.unassignUserSelected != null){
        for (var i = 0; i < this.unassignUserSelected.length; i = i + 1) {
          try {
            await DatabaseLib.deleteRequest('strapiDatabase','assigned-contracts',this.$store.getters.getToken,this.unassignUserSelected[i].id);
          }
          catch(error) {
            window.scroll({top: 0, behavior: 'smooth'});
            this.p4 = true;
            this.submitProcessing = false;
            return;
          }
        }
      }
        
        // Sets the component data to unselect everything from the table. This prevents rows still being selected after submit.
        document.getElementById('userUnassignTable').__vue__.selected = [];

        this.p3 = true;
        this.submitProcessing = false;
        this.displayUserAssignment(this.selectedData);
    }
  },

    mounted() {
      this.populateContracts();
      this.populateSupervisor();
    }
  }
</script>

<style scoped>
  .QuadrusButton {
	background-color: #407ec9 !important
}
</style>