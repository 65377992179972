<template>
    <div class="page-start">
        <mdb-row class="justify-content-center">
		<mdb-col col="10">
            <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p1" @closeAlert="p1=false" dismiss>
                There was an error retrieving submitted wars! Please try again later. Error Code P1
            </mdb-alert>
            <QDataTable :filterWords="this.wars.filterWords" :rowData="this.wars.rowData" :headers="this.wars.columns" 
                :hasBtn="true" :btnName="'Download'" @use-method="downloadPDF"
                :hasBtn2="true" :btnName2="'Preview'" @use-method-2="previewPDF"
            />
        </mdb-col>
        </mdb-row>
    </div>
</template>

<script>
import { mdbCol, mdbRow, mdbAlert } from 'mdbvue';
import QDataTable from '@/components/QDataTable.vue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';

import * as PDFDownload from '@/assets/js/downloadPDF.js';

export default {
    name: 'EditSavedWar',
    components: {
        mdbCol,
        mdbRow,
        mdbAlert,
        QDataTable
    },
    data() {
        return {
            wars: {
                columns: [
                    {
                        colTitle: 'Week',
                        sortBy: 'dateSubmitted'
                    },
                    {
                        colTitle: 'Project',
                        sortBy: 'projectTask'
                    },
                    {
                        colTitle: 'Action'
                    }
                ],
                rowData: [],
                filterWords: [
                    'dateSubmitted',
                    'projectTask'
                ]
            },
            p1: false
        }
    },
    methods: {
        fillTable: async function() {
            let token = this.$store.getters.getToken;
            let query = '';
            let response = '';
            if(this.$store.getters.getGroups.includes('Administration') || this.$store.getters.getGroups.includes('Supervisor')){
                this.wars = {
                    columns: [
                        {
                            colTitle: 'Week',
                            sortBy: 'dateSubmitted'
                        },
                        {
                            colTitle: 'Project',
                            sortBy: 'projectTask'
                        },
                        {
                            colTitle: 'Name',
                            sortBy: 'name'
                        },
                        {
                            colTitle: 'Action'
                        }
                    ],
                    rowData: [],
                    filterWords: [
                        'dateSubmitted',
                        'projectTask',
                        'name'
                    ]
                }
            }
            if(this.$store.getters.getGroups.includes('Administration')){
                try 
                {
                    query = query + '&filters[submitted][$eq][0]='+true;
                    response = await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);
                } 
                catch 
                {
                    window.scroll({top: 0, behavior: 'smooth'});
                    this.p1 = true;
                    return;
                }
            }
            else if(this.$store.getters.getGroups.includes('Supervisor')) {
                try 
                {
                    query = 'filters[blocked][$eq][0]='+false+'&filters[$or][1][reportsTo][$eq]='+this.$store.getters.getUser+'&filters[$or][2][username][$eq]='+this.$store.getters.getUser;
                    response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
                    query = 'filters[submitted][$eq][0]='+true;
                    let counter = 0;
                    response.data.forEach(tableRow => {
                        counter = counter + 1;
                        query = query + '&filters[username][$in]['+counter+']='+tableRow.username;
                    })
                    response = await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);
                } 
                catch 
                {
                    window.scroll({top: 0, behavior: 'smooth'});
                    this.p1 = true;
                    return;
                }
            } else {
                try {
                    query = 'filters[submitted][$eq][0]='+true+'&filters[username][$eq][1]='+this.$store.getters.getUser;
                    response = await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);
                } catch(error) {
                    window.scroll({top: 0, behavior: 'smooth'});
                    this.p1 = true;
                    return;
                }
            }
            if(response != ''){
                response.data.data.forEach(tableRow => {
                    let startDate = new Date(tableRow.attributes.startDate)
                    let endDate =  new Date(tableRow.attributes.endDate)
        
                    this.wars.rowData.push({
                        id: tableRow.id,
                        name: tableRow.attributes.firstLastName,
                        dateSubmitted: (startDate.getUTCMonth() + 1) + "/" + startDate.getUTCDate() + "/" + startDate.getUTCFullYear().toString().substr(-2) + " - " + (endDate.getUTCMonth() + 1) + "/" + endDate.getUTCDate() + "/" + endDate.getUTCFullYear().toString().substr(-2),
                        projectTask: tableRow.attributes.projectTask,
                        activity: tableRow.attributes.activity,
                        division: tableRow.attributes.division,
                    })
                });
            }
            
            return 0;
        },

        downloadPDF: function(war) {
            PDFDownload.fileDownload(war.id, war.projectTask, war.activity, war.name, war.dateSubmitted, war.division, 'Download')
        },

        previewPDF: function(war) {
            PDFDownload.fileDownload(war.id, war.projectTask, war.activity, war.name, war.dateSubmitted, war.division, 'Preview')
        }
    },

    mounted() {
        this.fillTable();
    }
    
}
</script>
