<template>

<div class="page-start">
    <mdb-row class="justify-content-center" >
        <mdb-col class="pt-3" col="10" style="background-color: white;">
            <h2 class="h2-responsive">Filter Options for viewing the visitor log</h2>
            <hr>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p1" @closeAlert="p1=false" dismiss>
					There was an error retrieving information from the server! Please try again later.
				</mdb-alert>
        </mdb-col>
				
    </mdb-row>
		<div v-if="this.$store.getters.getGroups.includes('Administration') || this.$store.getters.getGroups.includes('Supervisor')">
			<mdb-row class="justify-content-center">
					<mdb-col class="pt-3" col="10" style="background-color: white;">
							<p>By default, the previous month will be grabbed. If you want a specific month, use this option </p>
							<hr>
              <div class="m-3">
                <mdb-select label="Month" @getValue="setScopeMonth" v-model="monthOptions" required />
                <mdb-input type="number" label="Year" v-model="scopeYear" :min="2021" />
              </div>
					</mdb-col>
			</mdb-row>
			<mdb-row class="justify-content-center">
        <mdb-col class="pt-3 pb-3" col="10" style="background-color: white;">
            <mdb-btn size="lg" color="info" style="width: 100%; max-width: 50%;" v-on:click="populateReports">
                Apply Filter
            </mdb-btn>
        </mdb-col>
			</mdb-row>
		</div>
		<div v-else>
			<mdb-row class="justify-content-center">
					<mdb-col class="pt-3" col="10" style="background-color: white;">
						<mdb-alert id="feedback-failure2" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
							There was an error retrieving information from the server! Please try again later.
						</mdb-alert>

						<p>By default, the previous month will be grabbed. If you want a specific month, select an option below. </p>
						<hr>

						<mdb-select label="Month" @getValue="setScopeMonth" v-model="monthOptions" required />
						<mdb-input type="number" label="Year" v-model="scopeYear" :min="2021" />

					</mdb-col>
			</mdb-row>
			<mdb-row class="justify-content-center">
        <mdb-col class="pt-3 pb-3" col="10" style="background-color: white;">
            <mdb-btn size="lg" color="info" style="width: 100%; max-width: 50%;" v-on:click="populateReports">
                Apply Filter
            </mdb-btn>
        </mdb-col>
			</mdb-row>
		</div>
    
    <br>

		<!-- Monthly Report Table -->
    <mdb-row class="justify-content-center" >
        <mdb-col col="10" class="pl-0 pr-0" style="background-color: white;">
            <hr>
            <h3 class="h3-responsive">Visitor Log {{ this.selectedMonth }}</h3>
            <hr>
            <mdb-row class="justify-content-center">
                <mdb-col col="10" style="background-color: white;">
                    <download-csv :data="this.visitors.rowData" name="VisitorLog.csv" :labels="this.visitors.columns" :class='this.visitors.rowData.length == 0 ? "disabled btn QuadrusButton" : "btn QuadrusButton"' :disabled="this.visitors.rowData.length == 0">Build Report</download-csv>
                    <mdb-btn class='QuadrusButton' v-if="submitProcessing">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Processing...
                    </mdb-btn>
                </mdb-col>
            </mdb-row>
            <QDataTable :filterWords="this.visitors.filterWords" :rowData="this.visitors.rowData" :headers="this.visitors.columns"
            />
        </mdb-col>
    </mdb-row>
		<!-- /Monthly Report Table -->
</div>

</template>
<script>
import Vue from 'vue';
import { mdbCol, mdbRow, mdbInput, mdbSelect, mdbBtn, mdbAlert } from 'mdbvue';
import QDataTable from '@/components/QDataTable.vue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';
import * as JsonCSV from 'vue-json-csv';
Vue.component('downloadCsv', JsonCSV)


export default {
    components: {
        mdbCol,
        mdbRow,
        mdbInput,
        mdbSelect,
        mdbBtn,
        mdbAlert,
        QDataTable
    },
    data() {
        return {
          scopeMonth: '',
          scopeYear: '',
          scopeDisable: false,
          applyDisableMonthly: true,
          selectedMonth: '',

          monthOptions: [
              { text: 'January', value: '1', selected: false },
              { text: 'February', value: '2', selected: false },
              { text: 'March', value: '3', selected: false },
              { text: 'April', value: '4', selected: false },
              { text: 'May', value: '5', selected: false },
              { text: 'June', value: '6', selected: false },
              { text: 'July', value: '7', selected: false },
              { text: 'August', value: '8', selected: false },
              { text: 'September', value: '9', selected: false },
              { text: 'October', value: '10', selected: false },
              { text: 'November', value: '11', selected: false },
              { text: 'December', value: '12', selected: false }
          ],
          visitors: {
              columns: [
                  {
                      colTitle: 'Visitor',
                      sortBy: 'fullName'
                  },
                  {
                      colTitle: 'Email',
                      sortBy: 'email'
                  },
                  {
                      colTitle: 'Date',
                      sortBy: 'dayOfVisit'
                  },
                  {
                      colTitle: 'Company',
                      sortBy: 'companyName'
                  },
                  {
                      colTitle: 'Person To See',
                      sortBy: 'personToSee'
                  }
              ],
              rowData: [],
              filterWords: [
                  'fullName',
                  'email',
                  'dayOfVisit',
                  'companyName',
                  'personToSee',
              ]
          },

          submitProcessing: false,

          p1: false,
          p2: false,
      }
    }, 
    methods: {
        setScopeMonth: function(value)
        {
            this.scopeMonth = parseInt(value);
        },

        populateReports: async function()
        {
            this.visitors.rowData = [];
            this.applyDisableMonthly = false;
            let endMonth = parseInt(this.scopeMonth)+1;
            let endYear = parseInt(this.scopeYear);
            let token = this.$store.getters.getToken;
            let query = '';

            if (endMonth == 13)
            {
                endMonth = 1;
                endYear = parseInt(this.scopeYear) + 1;
            }
            this.selectedMonth = "for " + this.monthOptions[this.scopeMonth - 1].text + " " + this.scopeYear;

            query = 'filters[dayOfVisit][$gte]='+this.scopeYear+'-'+('0'+this.scopeMonth).slice(-2)+'-01';
            query = query + '&filters[dayOfVisit][$lt]='+endYear+'-'+('0' + (endMonth)).slice(-2) + '-01';

            let response = await DatabaseLib.getRequest('strapiDatabase','visitors',token,query);
            response.data.data.forEach(tableRow => {           
              this.visitors.rowData.push({
                  id: tableRow.id,
                  fullName: tableRow.attributes.firstName + " " + tableRow.attributes.lastName,
                  email: tableRow.attributes.email,
                  dayOfVisit: new Date(tableRow.attributes.dayOfVisit).toISOString().split('T')[0],
                  companyName: tableRow.attributes.companyName,
                  personToSee: tableRow.attributes.personToSee
              })
          })
        },
        
        setDateScope: function() {
            let todayMonth = new Date().getMonth();

            if(todayMonth == 0) { 
                this.monthOptions[11].selected = true;
                this.scopeMonth = this.monthOptions[11].value;
                this.scopeYear = new Date().getFullYear() - 1;
            } else {
                this.monthOptions[todayMonth-1].selected = true;
                this.scopeMonth = this.monthOptions[todayMonth-1].value;
                this.scopeYear = new Date().getFullYear();
            }

        },
    },

    watch: 
    {
        filter: function()
        {
            this.applyDisableMonthly = true;
        }
    },

    mounted()
    {
			this.setDateScope();
    }
}
</script>