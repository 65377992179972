<template>
  <div>
    <mdb-side-nav-2
      v-model="show"
      :data="navigation"
      color="white"
      over
      :sidenav-class="['grey', 'darken-3']"
    >
      <div slot="header" class="mt-5">
        <div class="p-3 pt-5">
            <img
              src="../assets/Quadrus Logo grayscale.png"
              class="img-fluid"
            />
        </div>
        <hr />
        <ul class="list-unstyled d-flex justify-content-center">
          <mdb-col>
          <li class="mb-2">
            <a href="https://www.facebook.com/QuadrusCorporation/" target="_blank" class="px-3 icons-sm gplus-ic">
              <mdb-icon color="white" fab icon="facebook" size="2x" />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/quadrus-corporation/mycompany/" target="_blank" class="px-3 icons-sm tw-ic">
              <mdb-icon color="white" fab icon="linkedin" size="2x" />
            </a>
          </li>
          </mdb-col>
          <mdb-col>
          <li class="mb-2">
            <a href="https://quadruscorp.com" target="_blank">
              <img src="../assets/Quadrus Logo wht.png" style="max-height: 2.5em;">
            </a>
          </li>
          <li>
            <a href="https://quadruscorp.bamboohr.com/" target="_blank">
              <img src="../assets/BambooHR.png" style="max-height: 1.2em;">
            </a>
          </li>
          </mdb-col>
        </ul>
        <hr />

      </div>

      <mdb-navbar
        slot="nav"
        tag="div"
        position="top"
        dark
        :toggler="false"
        :style="{ zIndex: '1061', backgroundColor: 'black'}"
      >

          <mdb-navbar-nav left>
            <div class="align-items-center">
              <mdb-icon
                icon="bars"
                class="white-text"
                size="lg"
                @click.native="show = !show"
                :style="{cursor: 'pointer'}"
              />
              <router-link to="/"><img class="pl-3" src="../assets/Quadrus Logo color reverse no bkgrd.png" height="30" alt="placeholder picture"></router-link>

            </div>
          </mdb-navbar-nav>
          <mdb-avatar class="mr-2">
            <img class="rounded-circle img-fluid" :src="$store.getters.getAvatar" height="70" width="70" alt="placeholder picture">
          </mdb-avatar>
          <mdb-dropdown>
            <mdb-dropdown-toggle slot="toggle" style="color: #000000; background-color: #FFFFFF">{{ username }}</mdb-dropdown-toggle>
            <mdb-dropdown-menu>
              <mdb-dropdown-item><router-link to="/My-Account">My Account</router-link></mdb-dropdown-item>
              <div class="dropdown-divider"></div>
              <mdb-dropdown-item @click="logout">Logout</mdb-dropdown-item>
            </mdb-dropdown-menu>
          </mdb-dropdown>
      </mdb-navbar>


    </mdb-side-nav-2>
  </div>
</template>

<script>

  //import Verify from '../../public/js/verify.js';

  import {
    mdbNavbar,
    mdbNavbarNav,
    mdbSideNav2,
    mdbIcon,
    mdbResize,
    mdbDropdown, 
    mdbDropdownItem, 
    mdbDropdownMenu, 
    mdbDropdownToggle,
    mdbAvatar,
    mdbCol
  } from "mdbvue";

  export default {
    name: "DoubleNavigationPagev1",
    components: {
      mdbNavbar,
      mdbNavbarNav,
      mdbSideNav2,
      mdbIcon,
      mdbDropdown,
      mdbDropdownItem,
      mdbDropdownMenu,
      mdbDropdownToggle,
      mdbAvatar,
      mdbCol
    },
    data() {
      return {
        show: false,
        over: false,
        username: "",
        timeoutInMS: 3600000, // 1 hour -> 60 * 60 * 1000
        timeoutId: 0
      };
    },
    methods: {
      checkWidth() {
        this.over = window.innerWidth < 900;
      },
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      },
      startTimer: function() { 
        this.timeoutId = setTimeout(this.logout, this.timeoutInMS);
      },
      resetTimer: function() {
        clearTimeout(this.timeoutId);
        if(this.$route.name !=='Login'){
          this.startTimer();
        }
      },
      setupTimers: function () {
        if(this.$route.name !=='Login'){
          document.addEventListener("keypress", this.resetTimer, false);
          document.addEventListener("mousemove", this.resetTimer, false);
          document.addEventListener("mousedown", this.resetTimer, false);
          document.addEventListener("touchmove", this.resetTimer, false);
          this.startTimer();
        }
      },

      getUsername: function() { this.username = this.$store.getters.getUser;}
    },
    directives: {
      mdbResize
    },

    computed: {
      navigation() {
        let navigationArray = [];

        navigationArray.push(
          {
            name: "Home",
            icon: "home",
            to: "/"
          }
        );
        if (this.$store.getters.getGroups.includes("Administration"))
        {
          navigationArray.push(
            {
              name: "Administration",
              icon: "toolbox",
              children: [
                {
                  name: "Manage Contract Data",
                  to: "/AssignContractData"
                },
                {
                  name: "Create New User",
                  to: "/New-User"
                },
                {
                  name: "View/Edit Users",
                  to: "/View-Edit-User-Page"
                },
                {
                  name: "Edit Home Page",
                  to: "/Edit-Homepage"
                },
                {
                  name: "Visitor Log",
                  to: "/Visitor-Information"
                }
              ]
            }
          );
          navigationArray.push(
            {
              name: "Administrator Tools",
              icon: "clipboard-list",
              children: [
                {
                  name: "WAR Submission Status",
                  to: "/Administrator-Tools/WARSubmissionStatus"
                }
              ]
            },
          );
        }

        if (this.$store.getters.getGroups.includes("Employee"))
        {
          navigationArray.push(
            {
              name: "WAR Reports",
              icon: "file-alt",
              children: [
                {
                  name: "Create New Report",
                  to: "/WAR-Reports/New-War"
                },
                {
                  name: "View Submitted Reports",
                  to: "/WAR-Reports/Submitted-War"
                },
                {
                  name: "Edit Saved Reports",
                  to: "/WAR-Reports/Edit-Saved-War"
                },
                {
                  name: "View Monthly Report",
                  to: "/WAR-Reports/Submitted-Monthly"
                }
              ]
            },
            {
              name: "Project Overview",
              icon: "map",
              children: [
                {
                  name: "QPR Form",
                  to: "/Project-Overview/QPR-Form"
                }
              ]
            },
            {
              name: "IT Support",
              icon: "hammer",
              children: [
                {
                  name: "Submit a Support Ticket",
                  to: "/IT/SubmitSupportTicket"
                }
              ]
            },
          )
        }

        /*
        navigationArray.push(
          {
            name: "Operations",
            icon: "cogs",
            children: [
              {
                name: "Create QPR",
                to: "/New-QPR"
              },
              {
                name: "View QPR",
                to: "/View-QPR"
              }
            ]
          }
        );
        */

        /*
        navigationArray.push(
          {
            name: "Corp. Development",
            icon: "road",
            children: [
              {
                name: "Manage",
                to: "/Corp-Dev-Manage"
              },
              {
                name: "View",
                to: "/Corp-Dev-View"
              },
              {
                name: "Monthly Report",
                to: "/Corp-Dev-Monthly-Report"
              },
              {
              name: "Yearly Report",
              to: "/Corp-Dev-Yearly-Report"
              }
            ]
          }
        );
        */

        /*
        navigationArray.push(
          {
            name: "Training",
            icon: "graduation-cap",
            to: "/Training"
          }
        );
        */

        if (this.$store.getters.getGroups.includes("Employee"))
        {
          navigationArray.push(
            {
              name: "Resources",
              icon: "book-open",
              children: [
                {
                  name: "Employee Directory",
                  to: "/Resources/Employee-Directory"
                }
              ]
            }
          );
        }
        
        if(this.$store.getters.getGroups.includes("IT")) {
          navigationArray.find(element => element.name == "IT Support").children.push(
            {
              name: "Manage Support Tickets",
              to: "/IT/SupportTicket"
            },
            {
              name: "Asset Tracking",
              to: "/IT/AssetTracking"
            }/*,
            {
              name: "Reset Password",
              to: "/IT/ResetUserPassword"
            }*/
          )
        }

        if(this.$store.getters.getGroups.includes("Administration")) {
          navigationArray.find(element => element.name == "Project Overview").children.push(
            {
              name: "View QPR",
              to: "/Project-Overview/View-QPR"
            }
          )
        }
        /*if(this.$store.getters.getGroups.includes("Supervisor")) {
          navigationArray.push(
            {
              name: "Supervisor Tools",
              icon: "magic",
              children: [
                {
                  name: "Timestamp Report",
                  to: "/Supervisor-Tools/TimestampReport"
                }
              ]
            },
          );

          navigationArray.find(obj => obj.name == 'Project Overview').children.push(
            {
              name: "View QPR",
              to: "/Project-Overview/View-QPR"
            }
          )
        }*/
        
        /*if(this.$store.getters.getGroups.includes("Administration")) {
          navigationArray.push(
            {
              name: "Administrator Tools",
              icon: "clipboard-list",
              children: [
                {
                  name: "WAR Submission Status",
                  to: "/Administrator-Tools/WARSubmissionStatus"
                }
              ]
            },
          );
        }*/
        
        return navigationArray;
      }
    },
    beforeMount() {
      this.setupTimers()
    },
    mounted() {
      this.getUsername();
    }
  };
</script>
