//===========================================================================================
//
//  Descripton:
//  -----------------------------------------------------------------------------------------
//  This javascript file serves the purpose of abstracting the database calls and establishing
//  constant endpoints for the requests. 
//
//  How to use:
//  -----------------------------------------------------------------------------------------
//  Because there are no constructors, the endpoints and database names to reference the endpoint
//  have to be defined by hand. This will determine which endpoint to use when passing in parameters 
//  to the function.
//
//  Parameters:
//  -----------------------------------------------------------------------------------------
//  Each function has 4 parameters:
//
//
//  Parameters :
//  __________________________________________________________________________________
//  Name	     |    Type	   |	Description
//  -----------|-------------|--------------------------------------------------------	
//	database   |    String   |  This will determine which database to use. In order to specify 
//             |             |  this correctly, the location endpoint should be defined below. 
//             |             |  These endpoints should be passed as a string when passing as a parameter.
//  -----------|-------------|--------------------------------------------------------	
//  table      |    String   |  This will determine which table in the specified database to use.
//  -----------|-------------|--------------------------------------------------------	
//  token      |    String   |  This should be the authetication token that is used by the user. Note that
//             |             |  this is genreally done with the getter this.$store.getters.getToken.
//  -----------|-------------|--------------------------------------------------------
// 
//
//  ----------------------------------------------------------------------------------
//  Methods that  | Name       |  Type       |  Description
//  use it        |            |             |
//  ----------------------------------------------------------------------------------
//  getRequest    | builtQuery |    String   |  Optional: This is a built query to send with the request. Refer to
//                |            |             |  https://strapi.io/documentation/developer-docs/latest/developer-resources/content-api/content-api.html#filters
//                |            |             |  on instruction on how to build a proper query.
//                |            |             |  NOTE: Some require the id of the database entry instead. Check below.
//  ----------------------------------------------------------------------------------
//  deleteRequest,| ID         |    String   |  Instead of having a query, the delete and put requests
//  putRequest    |            |    integer  |  identify the object by the ID in the database.
//  ----------------------------------------------------------------------------------
//  putRequest,   | data       |(key-> value)|  This is the data that should be sent with the request.
//  postRequest   |            |    array    |  The functions read it as a key->value array.
//                |            |             |  For Example:
//                |            |             |     let data = {
//                |            |             |         data1: value1,
//                |            |             |         data2: value2,
//                |            |             |         data3: value3
//                |            |             |     }
//                |            |             |  data1, data2, data3, ... dataX should
//                |            |             |  be the name of the field in the database
//____________________________________________________________________________________
//
//  Example usage:
//  DatabaseLib.getRequest('endpointName','table-name',token,'query');
//
//===========================================================================================

import axios from 'axios';
//import { NULL } from 'node-sass';
//import { count } from 'node-fpdf/src/PHP_CoreFunctions';

//===========================================================================================
//  This section should be the location of the endpoints. These should be defined as a constant
//  and  the location should be a string with a valid address of where the database is being 
//  hosted.
//
//  For Example:
//  endpointName: 'http://192.168.0.1:8080/'
//
//  The endpointName variable can be any name, but it should be usable again.
//  You can also define AS MANY endpoints that is necessary.
//  
//===========================================================================================
const endpoints = {
    //strapiDatabase: 'http://localhost:1337/'
    //strapiDatabase: 'https://qcdb.vp0cfmgh2hhj2.us-east-1.cs.amazonlightsail.com/',
    //FQT
    //strapiDatabase: 'https://qcdb-fqt.vp0cfmgh2hhj2.us-east-1.cs.amazonlightsail.com/',
    //Live
    strapiDatabase: 'https://qcdb-v4.vp0cfmgh2hhj2.us-east-1.cs.amazonlightsail.com/',
    
    //strapiDatabase: 'http://192.168.77.93:1337/'
    //strapiDatabase: 'http://192.168.77.52:1337/'
    //strapiDatabase: 'http://192.168.56.101:1337/'
};

//===========================================================================================


//checkEndpoints: function() {
//    endpoints.forEach(endpoint => console.log(endpoint))
//},

//===========================================================================================
// getRequest pages through all database entries queried for and builds a result array to be 
//      returned to the calling page
//===========================================================================================

async function getRequest(database, table, token, builtQuery = '', /*page = true*/) {
        
    let pagedData;
    /*
    if (table != "users/me" && page != false) { // regular database call
        let numRecords = await pageDatabase(database, table + "/count", token, builtQuery);
        pagedData = {data:[]};
        for(let i = 0; i <= Math.floor(numRecords.data/100); i = i + 1) {
            let query = "pagination[start][0]="+(i * 100);
            let result = await pageDatabase(database, table, token, query + "&" + builtQuery);
            for(let j = 0; j < result.data.data.length; j = j + 1) {
                pagedData.data.push(result.data.data[j]);
            }
        }
    }
    else if (table == "users/me" || page == false) { // querying for the logged in user
        pagedData = await pageDatabase(database, table, token, builtQuery);
    }
    */
    pagedData = await pageDatabase(database, table, token, builtQuery);
    return pagedData;
}

//===========================================================================================
// pageDatabase grabs the chosen page from the database and returns it back to getRequest
//===========================================================================================

async function pageDatabase(database,table,token,builtQuery = '') {
    let connection = '';
    let locationQuery = '';
    connection = endpoints[database];

    locationQuery = connection+"api/"+table;

    return new Promise(resolve => {
        axios.get(locationQuery+'?'+builtQuery, { headers: { Authorization: 'Bearer '+token }}).then(response => {
            resolve(response);
        }).catch( error => { resolve(Promise.reject(error)) })
    });
}

//===========================================================================================
// deleteRequest does not accept a query, but the ID of the item to delete.
//===========================================================================================

async function deleteRequest(database,table,token,ID) {
    let connection = '';
    let locationQuery = '';

    connection = endpoints[database];

    locationQuery = connection+"api/"+table;

    return new Promise(resolve => {
        axios.delete(locationQuery+'/'+ID, { headers: { Authorization: 'Bearer '+token }}).then(response => {
            resolve(response);
        }).catch( error => { resolve(Promise.reject(error)) })
    });
}

//===========================================================================================
//
//  The PUT and POST requests require an additional parameter. Data that wants to be sent to the
//  database must be passed.
//
//  putRequest does not accept a query, but the ID of the item to update.
//
//===========================================================================================

async function putRequest(database,table,token,ID,data) {
    let connection = '';
    let locationQuery = '';

    connection = endpoints[database];

    locationQuery = connection+"api/"+table;

    return new Promise(resolve => {
        axios.put(locationQuery+'/'+ID, data, { headers: { Authorization: 'Bearer '+token, 'Content-Type': 'application/json' }}).then(response => {
            resolve(response);
        }).catch( error => { resolve(Promise.reject(error)) })
    });
}

async function postRequest(database,table,token,data) {

    let connection = '';
    let locationQuery = '';

    connection = endpoints[database];

    locationQuery = connection+"api/"+table;

    return new Promise(resolve => {
        axios.post(locationQuery, data, { headers: { Authorization: 'Bearer '+token, 'Content-Type': 'application/json' }}).then(response => {
            resolve(response);
        }).catch( error => { resolve(Promise.reject(error)) })
    });
}

//===========================================================================================
//
//  The getEndpoint function retrieves the requested database url for cases such as
//  acquiring the full path to an item in the media library.
//
//===========================================================================================

function getEndpoint(database) {
    return endpoints[database]; 
}

export { getRequest, deleteRequest, putRequest, postRequest, getEndpoint }
