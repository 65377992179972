<template>
<div class='page-start'>
	<mdb-container class="pb-3" fluid>

        <mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p1" @closeAlert="p1=false" dismiss>
            Success! Submitted Asset Data!
        </mdb-alert>
        <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
            There was an error submitting Asset. Please try again! Error Code P2
        </mdb-alert>
        <mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p3" @closeAlert="p3=false" dismiss>
            Success! Deleted Asset Data!
        </mdb-alert>
        <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p4" @closeAlert="p4=false" dismiss>
            There was an error deleting Asset. Please try again! Error Code P4
        </mdb-alert>
        <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p5" @closeAlert="p5=false" dismiss>
            There was an error fetching asset data. Please try again! Error Code P5
        </mdb-alert>
        <mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p6" @closeAlert="p6=false" dismiss>
            Successfully scanned QRCode!
        </mdb-alert>
        <mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p7" @closeAlert="p7=false" dismiss>
            Successfully scanned QRCode! QRCode is unused, add it below!
        </mdb-alert>
        <mdb-card class="mb-3 d-flex">
        <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Submit Asset Information</h3>
        <mdb-card-body>
            <form class="was-validated">
    
                <!-- For Desktop -->
                <div class="d-none d-md-block">
                  <mdb-row>
                      <mdb-col>
                          <mdb-date-picker-2 v-model="assetDate" :disabled="manageAssetID != '' " label='Date' id="dateClosed" title='Date' disabledFuture required invalid-feedback="Please Input Date" valid-feedback="Looks Good!"/>
                      </mdb-col>
                      <mdb-col>
                          <mdb-input type="number" :min="1" :max="9999999999" label="Asset Number" v-model="assetNumber" required invalid-feedback="Please Input Asset Number" valid-feedback="Looks Good!"/>
                      </mdb-col>
                      <mdb-col>
                          <mdb-input label="Location" v-model="assetLocation" required invalid-feedback="Please Input Asset Location" valid-feedback="Looks Good!"/>
                      </mdb-col>
                  </mdb-row>
                </div>
                <!-- -->
                <!-- For Mobile -->
                <div class="d-block d-md-none">
                  <mdb-row>
                    <mdb-col>
                      <mdb-date-picker-2 v-model="assetDate" :disabled="manageAssetID != '' " label='Date' id="dateClosed" title='Date' disabledFuture required invalid-feedback="Please Input Date" valid-feedback="Looks Good!"/>
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col>
                        <mdb-input type="number" :min="1" :max="9999999999" label="Asset Number" v-model="assetNumber" required invalid-feedback="Please Input Asset Number" valid-feedback="Looks Good!"/>
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col>
                        <mdb-input label="Location" v-model="assetLocation" required invalid-feedback="Please Input Asset Location" valid-feedback="Looks Good!"/>
                    </mdb-col>
                  </mdb-row>
                </div>
                <!-- -->
                <mdb-row>
                    <mdb-col>
                        <mdb-input label="Item Name" v-model="assetItem" required invalid-feedback="Please Input Asset Name" valid-feedback="Looks Good!" maxlength="50" />
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col>
                        <mdb-input label="Item Description" v-model="assetDescription" required invalid-feedback="Please Input Asset Description" valid-feedback="Looks Good!" maxlength="120"/>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col>
                        <mdb-input label="Serial Number" v-model="assetSerialNumber" required invalid-feedback="Please Input Asset Serial Number" valid-feedback="Looks Good!" maxlength="60"/>
                    </mdb-col>
                </mdb-row>

                <mdb-btn id="submitAddAsset" class='QuadrusButton' size="lg"  v-on:click="submitAsset">Submit</mdb-btn>
                <br>
                <mdb-btn id="clearAsset" color="danger" size="lg" v-on:click="clearAssetForm">Clear</mdb-btn>
            </form>
        </mdb-card-body>
        </mdb-card>

        <mdb-row class="justify-content-center mb-4">
        <qrcode-stream ref="QRCodeCamera" class="pt-3" v-if="QRCamera" :camera="camera" @decode="onDecode"></qrcode-stream>
        <mdb-btn v-if="!QRCamera" class='QuadrusButton d-block d-md-none' size="lg"  v-on:click="enableCamera">Enable QR Code Scanner</mdb-btn>
        </mdb-row>

        <mdb-card class="mb-3 d-flex">
        <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Quadrus Corporation Assets</h3>
        <mdb-card-body>
            <download-csv class="btn btn-primary" :data="assetData.rowData" name="assetReport.csv">Generate Report</download-csv>
            <QDataTable id='assetTable' :rowData="this.assetData.rowData" :filterWords="this.assetData.filterWords" :headers="this.assetData.columns" 
            :btnName="'Manage'" :hasBtn="true" @use-method="manageAsset" :hasDelete="true" @delete="deleteAsset" @deleteInfo="assetInfo">
            <p>This will remove the asset from the list and cannot be recovered.</p>
            <p>Do you wish to continue?</p>
            </QDataTable>
        </mdb-card-body>
        </mdb-card>

    </mdb-container>
</div>
</template>
<script>
import Vue from 'vue'
import { mdbInput, mdbCard, mdbBtn, mdbCol, mdbRow, mdbDatePicker2, mdbCardBody, mdbContainer, mdbAlert } from 'mdbvue';
import { QrcodeStream } from 'vue-qrcode-reader'
import QDataTable from '@/components/QDataTable.vue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';
import JsonCSV from 'vue-json-csv';

Vue.component('downloadCsv', JsonCSV)

export default {
	name: 'ASSETS',
	components: {
    mdbInput,
    mdbCard,
    mdbBtn,
    mdbCol,
    mdbRow,
    mdbDatePicker2,
    mdbCardBody,
    mdbContainer,
    mdbAlert,
    QDataTable,
    QrcodeStream
    },
    
    data() {
        return {

            QRCamera: false,
            camera: 'rear',
            assetNumberQR: { assetNumber: '' },

            p1: false,
            p2: false,
            p3: false,
            p4: false,
            p5: false,
            p6: false,
            p7: false,

            assetDate: '',
            assetNumber: '',
            assetLocation: '',
            assetItem: '',
            assetDescription: '',
            assetSerialNumber: '',
            
            manageAssetID: '',
            selectedAssetID: '',

            // Asset Table
            assetData: {
                columns: [  
                    {
                        colTitle: 'Date Submitted',
                        sortBy: 'dateSubmit'
                    },
                    {
                        colTitle: 'Asset Number',
                        sortBy: 'assetNumber'
                    },
                    {
                        colTitle: 'Asset Location',
                        sortBy: 'location'
                    },
                    {
                        colTitle: 'Item Name',
                        sortBy: 'itemName'
                    },
                    {
                        colTitle: 'Asset Description',
                        sortBy: 'itemDescription'
                    },
                    {
                        colTitle: 'Serial Number',
                        sortBy: 'serialNumber'
                    }
                ],
                rowData: [],
                filterWords: [
                    'dateSubmit',
                    'assetNumber',
                    'location',
                    'itemName',
                    'itemDescription',
                    'serialNumber'
                ]
            }

        }
    },

    methods: {

        assetInfo: function(data) {
            this.selectedAssetID = data.id;
        },

        deleteAsset: async function() {
            let token = this.$store.getters.getToken;
                 
            try {
                await DatabaseLib.deleteRequest('strapiDatabase','asset-trackings',token,this.selectedAssetID);
            } catch(error) {
                window.scroll({top: 0, behavior: 'smooth'});
                this.p4 = true;
                return;
            }

            this.populateAssetData();

            window.scroll({top: 0, behavior: 'smooth'});
            this.p3 = true;
        },

        manageAsset: async function(data) {
            let token = this.$store.getters.getToken;
            let query = "filters[assetNumber][$eq][0]="+data.assetNumber;
           
            let response = '';
            try {
                response = await DatabaseLib.getRequest('strapiDatabase','asset-trackings',token,query);
            } catch(error) {
                window.scroll({top: 0, behavior: 'smooth'});
                this.p5 = true;
                return;
            }
            if(response.data.data.length > 0) {
              this.manageAssetID = response.data.data[0].id;
              this.assetDate = response.data.data[0].attributes.dateSubmit;
              this.assetNumber = response.data.data[0].attributes.assetNumber;
              this.assetLocation = response.data.data[0].attributes.location;
              this.assetItem = response.data.data[0].attributes.itemName;
              this.assetDescription = response.data.data[0].attributes.itemDescription;
              this.assetSerialNumber = response.data.data[0].attributes.serialNumber;

              this.p6 = true;

            } else if(response.data.data.length == 0) {

              this.assetNumber = data.assetNumber;
              this.p7 = true;
            }

            window.scroll({top: 0, behavior: 'smooth'});
        },

        clearAssetForm: function() {
            this.assetDate = "";
            this.assetNumber = "";
            this.assetLocation = "";
            this.assetItem = "";
            this.assetDescription = "";
            this.assetSerialNumber = "";

            this.manageAssetID = "";
        },

        submitAsset: async function() {
            let token = this.$store.getters.getToken;
            
            this.assetNumber = this.sanitizeInput(this.assetNumber);
            this.assetLocation = this.sanitizeInput(this.assetLocation);
            this.assetItem = this.sanitizeInput(this.assetItem);   
            this.assetDeacription = this.sanitizeInput(this.assetDeacription);
            this.assetSerialNumber = this.sanitizeInput(this.assetSerialNumber);

            let data = {
                data:{
                    assetNumber: this.assetNumber,
                    dateSubmit: new Date(this.assetDate),
                    location: this.assetLocation,
                    itemName: this.assetItem,
                    itemDescription: this.assetDescription,
                    serialNumber: this.assetSerialNumber
                }
            }
            
            if(this.manageAssetID != '' && this.assetDate) {
                try {
                    await DatabaseLib.putRequest('strapiDatabase','asset-trackings',token,this.manageAssetID,data);

                    window.scroll({top: 0, behavior: 'smooth'});
                    this.p1 = true;
                    this.clearAssetForm(); // Housekeeping

                    // Resets Asset Table after submiting new asset
                    this.populateAssetData();
                    this.manageAssetID = '';
                } catch(error) {
                    window.scroll({top: 0, behavior: 'smooth'});
                    this.p2 = true;
                    return;
                }
            } else if(this.assetDate) {
                try {
                    await DatabaseLib.postRequest('strapiDatabase','asset-trackings',token,data);

                    window.scroll({top: 0, behavior: 'smooth'});
                    this.p1 = true;
                    this.clearAssetForm(); // Housekeeping

                    // Resets Asset Table after submiting new Asset
                    this.populateAssetData();
                } catch(error) {
                    window.scroll({top: 0, behavior: 'smooth'});
                    this.p2 = true;
                    return;
                }
            } else {
                window.scroll({top: 0, behavior: 'smooth'});
                this.p2 = true;
            }

        },

        resetAssetTable: function() {
            this.assetData.rowData.splice(0);
        },

        enableCamera: function() {
          this.QRCamera = true;
          this.$nextTick(() => this.$refs.QRCodeCamera.$el.scrollIntoView({ block: "center", behavior: "smooth"}))
        },

        onDecode: function(value) {
          this.QRCamera = false;
          window.scroll({top: 0, behavior: 'smooth'}); 

          this.assetNumberQR.assetNumber = parseInt(value);
          this.manageAsset(this.assetNumberQR);

        },

        populateAssetData: async function() {
           /* let date = new Date();
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0');
            let day = String(date.getDate()).padStart(2, '0');
            this.assetDate = "" + year + "-" + month + "-" + day;
*/
            let token = this.$store.getters.getToken;
            let query = 'pagination[limit]=-1';
            let response = '';
            
            try {
                this.resetAssetTable();
                response = await DatabaseLib.getRequest('strapiDatabase','asset-trackings',token,query);
                for(let i = 0; i < response.data.data.length; i = i + 1) {
                    this.assetData.rowData.push({
                        id: response.data.data[i].id,
                        dateSubmit: response.data.data[i].attributes.dateSubmit,
                        assetNumber: response.data.data[i].attributes.assetNumber,
                        location: response.data.data[i].attributes.location,
                        itemName: response.data.data[i].attributes.itemName,
                        itemDescription: response.data.data[i].attributes.itemDescription,
                        serialNumber: response.data.data[i].attributes.serialNumber
                    }) 
                }
            } catch(error) {
                window.scroll({top: 0, behavior: 'smooth'});
                this.p5 = true;
                return;
            }
        },

        //===========================================================================================
		//	Method Name	:	sanitizeInput
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//	content					string			IN			Input to be sanitized for DB entry.
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	
		//===========================================================================================
		sanitizeInput(content) 
		{
            let input = this.$sanitize(content);
            input = input.replace(/&amp;/g, "&");
			return input;
        },
    },

    mounted() {
        this.populateAssetData();
    }
}
</script>